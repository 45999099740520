import "core-js/modules/es.array.push.js";
export default {
  props: ['menuIndex'],
  data() {
    return {
      menus: [{
        id: 1,
        name: '首页',
        img: require('@/static/img/teacher/menu/index.png'),
        imgCheck: require('@/static/img/teacher/menu/index-check.png'),
        path: '/teacher/teacherIndex'
      }, {
        id: 2,
        name: '活动',
        img: require('@/static/img/teacher/menu/active.png'),
        imgCheck: require('@/static/img/teacher/menu/active-check.png'),
        path: '/teacher/active/teacherActiveList'
      }, {
        id: 3,
        name: '我的',
        img: require('@/static/img/teacher/menu/person.png'),
        imgCheck: require('@/static/img/teacher/menu/person-check.png'),
        path: '/teacher/person/personIndex'
      }],
      checkIndex: 0
    };
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
  },
  methods: {
    getIndex(index) {
      this.checkIndex = index;
    },
    tiaozhuan(index, path) {
      this.checkIndex = index;
      this.$root.useRouter.push(path);
    }
  }
};