import { editSettlementRule } from "@/api/api";
import { stateList } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      cityValue: [],
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      Info: {
        id: '',
        name: '',
        state: 1
      },
      stateList: stateList,
      adminList: [],
      levelList: [],
      type: 0,
      isShenhe: true,
      checkMuneSer: [],
      rules: {
        name: [{
          required: true,
          message: "请输入规则名称",
          trigger: 'blur'
        }],
        start_time: [{
          required: true,
          message: "请选择开始时间",
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: "请选择结束时间",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
      //this.cityValue=[parseInt(this.Info.province),parseInt(this.Info.city),parseInt(this.Info.area)];//回显省市区县
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    getInfo() {
      agentInfo({
        id: this.Info.id
      }).then(res => {
        this.Info = res.data;
      });
    },
    checkAgent(state) {
      this.Info.state = state;
      agentCheck({
        id: this.Info.id,
        state: state
      }).then(res => {
        this.$root.resultMsg(res);
      });
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (this.Info.fixed_total_money) {
            let s = parseFloat(this.Info.fixed_agent_money || 0) + parseFloat(this.Info.fixed_baze_money || 0) + parseFloat(this.Info.fixed_system_money || 0);
            console.log(this.Info.fixed_total_money, s);
            if (parseFloat(this.Info.fixed_total_money) != s) {
              this.$root.ElMessage.error('结算总额=代理商结算金额+商户结算金额+平台结算金额');
              return false;
            }
          }
          editSettlementRule(this.Info).then(res => {
            this.$root.resultMsg(res);
          });
        }
      });
    }
  }
};