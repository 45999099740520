import { teacherEdit } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      user_type: localStorage.getItem('userType'),
      Info: {
        id: '',
        name: '',
        username: ''
      },
      isShenhe: false,
      rules: {
        username: [{
          required: true,
          message: "请输入教职工编号",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请输入教师名字",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
    }
    if (data.isShenhe) {
      this.isShenhe = true;
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          teacherEdit(this.Info).then(res => {
            this.$root.resultMsg(res);
          });
        }
      });
    },
    checkTeacher(state) {
      let data = {
        id: this.Info.id,
        state: state
      };
      if (this.Info.state != 1) {
        this.$root.ElMessage.error('当前用户状态不能审核');
        return false;
      }
      teacherEdit(data).then(res => {
        this.$root.resultMsg(res);
      });
    }
  }
};