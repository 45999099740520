import "core-js/modules/es.array.push.js";
import lineChart from '@/components/lineChart.vue';
import options from '@/utils/chartOption';
import { getSchoolActiveData } from "@/api/api";
export default {
  name: "index",
  components: {
    lineChart
  },
  data() {
    return {
      Info: {
        school_active_id: ''
      },
      chartData: [],
      alarmtresChartData: [],
      chartId: 'school'
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.school_active_id) {
      this.Info.school_active_id = data.school_active_id;
      this.getData();
    }
  },
  methods: {
    getData() {
      getSchoolActiveData(this.Info).then(res => {
        this.chartData = res.data;
        if (this.chartData.evaAnswer) {
          this.imageSrc(this.chartData.evaAnswer);
        }
        if (this.chartData.dataEva) {
          let chartPieData = options.pie;
          let seData = [];
          for (let i in this.chartData.dataEva) {
            seData.push({
              name: this.chartData.dataEva[i].eva_type,
              y: this.chartData.dataEva[i].num
            });
          }
          chartPieData.series[0].data = seData;
          this.alarmtresChartData = chartPieData;
          this.$refs.lineChart.showCharts('school', this.alarmtresChartData); //调用子组件方法
          this.$forceUpdate();
        }
      });
    },
    showGradeData(grade_code) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/gradeDataInfo",
        query: {
          school_active_id: this.Info.school_active_id,
          grade_code: grade_code
        }
      });
    },
    imageSrc(data) {
      //V3 动态引入图片
      for (let i in data) {
        import('@/static/img/xunzhang/' + data[i].option_id + '.png').then(res => {
          this.chartData.evaAnswer[i]['img'] = res.default;
        });
      }
    },
    sumScoreSet(ob) {
      let sum = 0;
      for (let i in ob) {
        sum = sum + parseFloat(ob[i].d_sum);
      }
      return sum;
    }
  }
};