import { teacherEdit, getInfo, userList } from "@/api/api.js";
export default {
  props: ['menuIndex'],
  data() {
    return {
      Info: {
        name: '',
        teachertor_id: ''
      },
      teachertorList: []
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    this.getUserList();
    this.Info = JSON.parse(localStorage.getItem('user')) || [];
    if (!this.Info || this.Info.length == 0) {
      this.$root.ElMessage.error('登录超时');
      this.$root.useRouter.replace('/teacher/wxTeacherLogin');
    }
    this.getInfo();
  },
  methods: {
    getInfo() {
      getInfo().then(res => {
        this.Info = res.data;
      });
    },
    getUserList() {
      userList({
        table_name: 'e2_teachertor'
      }).then(res => {
        this.teachertorList = res.data;
      });
    },
    subData() {
      if (!this.Info.name || !this.Info.phone || !this.Info.id_no || !this.Info.img_card_z || !this.Info.img_card_f) {
        this.$root.ElMessage.error('请完善信息必填项，姓名、电话、身份证号、身份证正反面');
        return false;
      }
      this.Info.state = 1;
      teacherEdit(this.Info).then(res => {
        if (res.success) {
          window.localStorage.setItem('user', JSON.stringify(this.Info));
        }
        this.$root.resultMsg(res);
      });
    }
  }
};