import { checkApplyActive, getSchoolActiveInfo, getTeacherList, courseList } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      user_type: localStorage.getItem('userType'),
      school_active_id: '',
      Info: {},
      teamList: [],
      courseList: [],
      teacherList: [],
      rules: {
        start_time: [{
          required: true,
          message: "请选择开始时间",
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: "请选择结束时间",
          trigger: 'blur'
        }],
        day: [{
          required: true,
          message: "请输入活动天数",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.school_active_id = data.id;
      this.getInfo();
    }
    this.getTeacherList();
    this.getCourseList();
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInfo() {
      getSchoolActiveInfo({
        id: this.school_active_id
      }).then(res => {
        this.Info = res.data;
        if (this.Info.active) {
          if (this.Info.active.img_json) {
            this.Info.active.img_json = JSON.parse(this.Info.active.img_json);
          } else {
            this.Info.active.img_json = [];
          }
          if (this.Info.active.process) {
            this.Info.active.process = JSON.parse(this.Info.active.process);
          } else {
            this.Info.active.process = [];
          }
          if (this.Info.active.cost_detail_text) {
            this.Info.active.cost_detail_text = JSON.parse(this.Info.active.cost_detail_text);
          } else {
            this.Info.active.cost_detail_text = [];
          }
          ;
        }
      });
    },
    getTeacherList() {
      getTeacherList().then(res => {
        this.teacherList = res.data;
      });
    },
    getCourseList() {
      courseList().then(res => {
        this.courseList = res.data;
      });
    },
    subData(state) {
      //提交
      if (!this.Info.check_msg && state == 3) {
        this.$root.ElMessage.error('请填写失败原因');
        return false;
      }
      let data = {
        id: this.Info.id,
        check_msg: this.Info.check_msg,
        state: state,
        teamCourse: this.Info.teamCourse
      };
      checkApplyActive(data).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};