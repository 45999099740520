import { teamEdit, getGradeAll, getSchoolList } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      Info: {
        id: '',
        name: ''
      },
      gradeList: [],
      schoolList: [],
      rules: {
        code: [{
          required: true,
          message: "请输入班级编号",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请输入班级名称",
          trigger: 'blur'
        }],
        grade: [{
          required: true,
          message: "请选择年级",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    this.getGradeList();
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          teamEdit(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    getGradeList() {
      getSchoolList().then(res => {
        this.schoolList = res.data;
      });
      getGradeAll().then(res => {
        this.gradeList = res.data;
      });
    }
  }
};