import "core-js/modules/es.array.push.js";
import { getEvaCourseLinkInfo } from "@/api/api.js";
export default {
  data() {
    return {
      Info: [],
      operator_server_active_id: ''
    };
  },
  created() {},
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    this.operator_server_active_id = data.operator_server_active_id;
    if (data.id) {
      this.Info.id = data.id;
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      getEvaCourseLinkInfo({
        course_id: this.Info.id,
        operator_server_active_id: this.operator_server_active_id
      }).then(res => {
        this.Info = res.data;
      });
    },
    goTeacherEva(c) {
      if (c) {
        c = JSON.stringify(c);
      } else {
        c = '';
      }
      this.$root.useRouter.push({
        path: "/teacher/teacherEva/activeCourseEva",
        query: {
          operator_server_active_id: this.operator_server_active_id,
          info: c
        }
      });
    }
  }
};