import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-10c3e396"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "project-table",
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1,
  class: "fontSize16gayBB"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $data.tableData,
    "row-key": "grade_code",
    border: "",
    "default-expand-all": "",
    "header-cell-style": {
      'text-align': 'center',
      'color': '#999999',
      'padding': '16px 0',
      'font-size': '16px',
      'background-color': '#F6F6F6'
    },
    "cell-style": {
      'text-align': 'center',
      'padding': '16px 0'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "grade_name",
      label: "名称",
      width: "260"
    }, {
      default: _withCtx(scope => [scope.row.team_id ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.row.team ? scope.row.team.name : ''), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.grade_name), 1))]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "数据"
    }, {
      default: _withCtx(scope => [scope.row.team_id ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        link: "",
        type: "primary",
        onClick: $event => $options.teamData(scope.row.team_id)
      }, {
        default: _withCtx(() => [_createTextVNode("班级数据")]),
        _: 2
      }, 1032, ["onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
        key: 1,
        link: "",
        type: "primary",
        style: {
          "color": "blue",
          "font-weight": "bold"
        },
        onClick: $event => $options.gradeData(scope.row.grade_code)
      }, {
        default: _withCtx(() => [_createTextVNode("年级数据")]),
        _: 2
      }, 1032, ["onClick"]))]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])])])]);
}