import { crete_wx_qrcode } from "@/api/api";
import { showImage } from "@/utils/common";
import html2canvas from "html2canvas";
export default {
  name: "sharePoster",
  data() {
    return {
      db: require('@/static/img/active/bg.png'),
      defaultBg: '',
      codeImg: '',
      name: '',
      date: '',
      address: '',
      loading: true,
      staticImg: '',
      config: {
        event1_timer: null,
        event2_timer: null
      }
    };
  },
  mounted() {
    const convertDateFormat = dateStr => {
      let date = new Date(dateStr);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return year + '年' + month + '月' + day + '日';
    };
    const {
      params
    } = this.$route.query;
    let paramsArr = (decodeURIComponent(params) || '').split('allisi');
    console.log(paramsArr, '------params-');
    if (paramsArr.length > 0) {
      this.name = paramsArr[1];
      this.date = convertDateFormat(paramsArr[2]);
      this.address = paramsArr[3];
      crete_wx_qrcode(paramsArr[0]).then(res => {
        console.log(res, '------poster res--');
        this.codeImg = res.data.wx_qrcode_url;
        const imgPath = res.data.poster.img_path;
        console.log('海报地址：' + imgPath);
        if (imgPath) {
          console.log('海报地址进入：' + imgPath);
          this.defaultBg = this.showImage(imgPath);
          console.log('海报地址进入 end：');
        } else {
          console.log('海报地址没进入：' + this.db);
          this.defaultBg = this.db;
        }
        const e2Handler = (dom, height) => {
          html2canvas(dom, {}).then(canvas => {
            this.staticImg = canvas.toDataURL("image/png");
            this.$nextTick(() => {
              setTimeout(() => {
                const posterItem2 = document.getElementById('staticImgShare');
                posterItem2.style.height = `${height}px`;
                this.loading = false;
              }, 500);
            });
          });
        };
        const e1Handler = bgImg => {
          const {
            height
          } = bgImg.getBoundingClientRect();
          //
          const posterItem = document.getElementById('posterItem');
          posterItem.style.height = `${height}px`;

          //
          this.$nextTick(() => {
            this.config.event2_timer = setInterval(() => {
              console.log(2233);
              const dom = document.getElementById('posterItem');
              if (dom) {
                const {
                  height: hh
                } = dom.getBoundingClientRect();
                if (hh > 0) {
                  clearInterval(this.config.event2_timer);
                  e2Handler(dom, height);
                }
              }
            }, 100);
          });
        };
        this.$nextTick(() => {
          // e1
          this.config.event1_timer = setInterval(() => {
            console.log(1233);
            const bgImg = this.$refs.bgImg;
            if (bgImg) {
              const {
                height
              } = bgImg.getBoundingClientRect();
              console.log(height, '-------height-');
              if (height > 0) {
                clearInterval(this.config.event1_timer);
                e1Handler(bgImg);
              }
            }
          }, 100);
        });
      });
    }
  },
  methods: {
    showImage
  }
};