import "core-js/modules/es.array.push.js";
import { editCourse, getGradeAll, getEvaOption, courseInfoById } from "@/api/api";
import editor from "@/components/editor.vue";
export default {
  components: {
    editor
  },
  name: "index",
  data() {
    return {
      grades: [],
      course_id: '',
      Info: {
        id: '',
        name: '',
        code: '',
        type: '',
        teacherTips: [],
        systemTips: []
      },
      teacherTipsList: [],
      systemTipsList: [],
      showInfo: false,
      rules: {
        name: [{
          required: true,
          message: "请输入课程名称",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    this.getGradeList();
    this.getEvaOption();
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.course_id = data.id;
      this.getInfo();
    }
    if (data.showInfo == 1) {
      this.showInfo = true;
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    getInfo() {
      courseInfoById({
        id: this.course_id
      }).then(res => {
        this.Info = res.data;
        this.setText();
        if (this.Info.grade) {
          this.grades = this.Info.grade.split(',');
          this.grades = this.grades.map(function (data) {
            return +data;
          });
        }
        if (this.Info.teacherTips) {
          let teacherArr = [];
          for (let t in this.Info.teacherTips) {
            teacherArr.push([this.Info.teacherTips[t].eva_option_id, this.Info.teacherTips[t].option_tip_id]);
          }
          this.Info.teacherTips = teacherArr;
        }
        if (this.Info.systemTips) {
          let sysArr = [];
          for (let s in this.Info.systemTips) {
            sysArr.push([this.Info.systemTips[s].eva_option_id, this.Info.systemTips[s].option_tip_id]);
          }
          console.log(sysArr);
          this.Info.systemTips = sysArr;
        }
      });
    },
    getGradeList() {
      getGradeAll().then(res => {
        this.$nextTick(() => {
          this.gradeList = res.data;
        });
        //this.$forceUpdate();
        //this.$nextTick
      });
    },
    getEvaOption() {
      getEvaOption().then(res => {
        let list = res.data;
        let teacherTips = [];
        let systemTips = [];
        for (let l in list) {
          let teacherTip = {
            value: list[l].id,
            label: list[l].name,
            children: []
          };
          let systemTip = {
            value: list[l].id,
            label: list[l].name,
            children: []
          };
          for (let i in list[l].teacherTips) {
            teacherTip.children.push({
              value: list[l].teacherTips[i].id,
              label: list[l].teacherTips[i].name
            });
          }
          for (let i in list[l].systemTips) {
            systemTip.children.push({
              value: list[l].systemTips[i].id,
              label: list[l].systemTips[i].name
            });
          }
          teacherTips.push(teacherTip);
          systemTips.push(systemTip);
          this.teacherTipsList = teacherTips;
          this.systemTipsList = systemTips;
        }
      });
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (this.grades.length > 0) {
            this.Info.grade = this.grades.join(',');
            //this.$root.ElMessage.error('请选择适用年级');
            //return false;
          }
          editCourse(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    setText() {
      this.$refs.editor.setText(this.Info.text);
    },
    getText(html) {
      this.Info.text = html;
    }
  }
};