import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-77e84be6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  controls: "",
  style: {
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  return _openBlock(), _createElementBlock("div", {
    class: "image-container",
    style: _normalizeStyle($data.style)
  }, [!$options.isVideo && $props.src ? (_openBlock(), _createBlock(_component_el_image, {
    key: 0,
    fit: $props.fit,
    src: $props.src,
    "preview-src-list": $props.preview ? [$props.src] : [],
    style: _normalizeStyle({
      width: $props.imageWidthFill ? '100%' : 'auto'
    })
  }, null, 8, ["fit", "src", "preview-src-list", "style"])) : _createCommentVNode("", true), $options.isVideo && $props.src ? (_openBlock(), _createElementBlock("video", _hoisted_1, [_createElementVNode("source", {
    src: $props.src,
    type: "video/mp4"
  }, null, 8, _hoisted_2)])) : _createCommentVNode("", true)], 4);
}