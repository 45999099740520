import "core-js/modules/es.array.push.js";
import { base_order_page, wxTeacherLogin } from "@/api/api";
import menuDiv from "@/view/teacher/menu.vue";
export default {
  components: {
    menuDiv
  },
  data() {
    return {
      index: 1,
      List: [],
      code: '',
      pageInfo: {
        pageSize: 5,
        current: 1,
        state: [2, 3],
        List: [],
        totalPage: 0,
        order_no: '',
        today_date: ''
      }
    };
  },
  created() {
    //this.getOrder();
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    this.code = data.code;
    if (this.code) {
      this.wxLogin();
    }
    this.$refs.menuDiv.getIndex(0); //调用子组件方法
  },
  methods: {
    wxLogin() {
      wxTeacherLogin({
        code: this.code
      }).then(res => {
        if (res.success) {
          window.localStorage.setItem('token', res.data2);
          window.localStorage.setItem('userType', res.data.user_type);
          window.localStorage.setItem('user', JSON.stringify(res.data));
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    },
    getOrder() {
      base_order_page(this.pageInfo).then(res => {
        this.pageInfo.totalPage = res.data.totalPage;
        this.List = this.List.concat(res.data.records);
      });
    },
    loadMore() {
      if (this.pageInfo.current < this.pageInfo.totalPage) {
        this.pageInfo.current = this.pageInfo.current + 1;
        this.getOrder();
      }
    },
    goSao() {
      this.$root.useRouter.push({
        path: "/mobile/scanQrcode"
      });
    }
  }
};