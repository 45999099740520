import { login } from "@/api/api";
export default {
  data() {
    return {
      loginInfo: {
        username: '',
        password: '',
        type: 3
      },
      ticket: ''
    };
  },
  mounted() {// this.$root 获取App.vue中的变量和方法
  },
  methods: {
    subData() {
      if (!this.loginInfo.username) {
        this.$root.ElMessage.error({
          message: '请输入用户名'
        });
        return false;
      }
      if (!this.loginInfo.password) {
        this.$root.ElMessage.error({
          message: '请输入密码'
        });
        return false;
      }
      login(this.loginInfo).then(res => {
        if (res.success) {
          window.localStorage.setItem('token', res.data2);
          window.localStorage.setItem('userType', this.loginInfo.type);
          window.localStorage.setItem('user', JSON.stringify(res.data));
          if (this.loginInfo.type == 3) {
            if (res.data.state != 2) {
              //未审核通过的商户
              this.$root.ElMessage({
                message: '当前状态不能正常使用，请联系管理员审核',
                type: 'warning'
              });
              return false;
            }
          }
          this.$root.ElMessage({
            message: '登录成功',
            type: 'success'
          });
          this.$root.useRouter.replace("/mobile/index"); //跳转至对应角色的首页
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    }
  }
};