import { editSchoolNotice, getSchoolNoticeInfo, uploadFile } from "@/api/api";
import editor from "@/components/editor.vue";
export default {
  components: {
    editor
  },
  name: "index",
  props: ['Row'],
  data() {
    return {
      Info: {
        id: ''
      }
    };
  },
  mounted() {
    if (this.$root.useRoute.query.id) {
      this.Info.id = this.$root.useRoute.query.id;
      this.getInfo();
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInfo() {
      getSchoolNoticeInfo({
        id: this.Info.id
      }).then(res => {
        this.Info = res.data;
        this.setText();
      });
    },
    subData() {
      //提交
      if (!this.Info.title || !this.Info.content) {
        this.$root.ElMessage.error('标题和内容不能为空！');
        return false;
      }
      let method = 'post';
      if (this.Info.id) {
        method = 'put';
      }
      editSchoolNotice(this.Info, method).then(res => {
        this.$root.resultMsg(res);
      });
    },
    setText() {
      this.$refs.editor.setText(this.Info.content);
    },
    getText(html) {
      this.Info.content = html;
    },
    beforeAvatarUpload(e) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          this.Info.img_url = res.data;
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    }
  }
};