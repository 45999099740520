import {createRouter,createWebHashHistory} from 'vue-router' //createWebHashHistory
import Login from '@/view/login.vue'
import AutoLogin from '@/view/autoLogin.vue'

/* 教师公众号端 start */
import WxTeacherLogin from '@/view/teacher/wxTeacherLogin.vue'
import TeacherIndex from '@/view/teacher/teacherIndex.vue'
import TeacherMenu from '@/view/teacher/menu.vue'
import PersonIndex from '@/view/teacher/person/personIndex.vue'
import TeacherInfo from '@/view/teacher/person/teacherInfo.vue'
import TeacherActiveList from '@/view/teacher/active/teacherActiveList.vue'
import TeacherActiveInfo from '@/view/teacher/active/teacherActiveInfo.vue'
import TeacherCourseList from '@/view/teacher/teacherEva/teacherCourseList.vue'
import ActiveCourseInfo from '@/view/teacher/teacherEva/activeCourseInfo.vue'
import ActiveCourseEva from '@/view/teacher/teacherEva/activeCourseEva.vue'
import ReportDetails from '@/view/dataBand/reportDetails.vue'
import SharePoster from '@/view/dataBand/sharePoster.vue'
/* 教师公众号端 end */
import Home from '@/view/home.vue'
import Register from '@/view/base/register.vue'
import CheckBase from '@/view/base/checkBase.vue'
import CheckUrban from '@/view/urban/checkUrban.vue'
import SchoolEdit from '@/view/admin/adminSchool/schoolEdit.vue'
import CourseEdit from '@/view/base/baseCourse/courseEdit.vue'
import ActiveBaseEdit from '@/view/base/baseActive/activeBaseEdit.vue'
import ActiveBaseInfo from '@/view/school/schoolActive/activeBaseInfo.vue'
import ActiveApplyInfo from '@/view/base/activeApply/activeApplyInfo.vue'
import MyActiveInfo from '@/view/school/schoolActive/myActiveInfo.vue'
import BaseTeacherEdit from '@/view/base/baseUser/teacherEdit.vue'
import EvaBaseRate from '@/view/school/schoolEva/evaBaseRate.vue'
import TeamEdit from '@/view/school/schoolManage/teamEdit.vue'
import BaseInfo from "@/view/school/schoolActive/baseInfo";
import TeacherEdit from '@/view/base/baseUser/teacherEdit.vue'
import StudentEdit from '@/view/school/schoolUser/studentEdit.vue'
import SchoolEnjoyEdit from '@/view/school/schoolManage/schoolEnjoyEdit.vue'
import noticeEdit from '@/view/admin/notice/noticeEdit.vue'
import orderList from '@/view/base/order/orderList.vue'
import CheckOrderInfo from '@/view/mobile/checkOrderInfo.vue'
import GradeAndTeamDataList from '@/view/school/schoolActiveData/gradeAndTeamDataList.vue'
import SchoolDataInfo from '@/view/school/schoolActiveData/schoolDataInfo.vue'
import GradeDataInfo from '@/view/school/schoolActiveData/gradeDataInfo.vue'
import TeamDataInfo from '@/view/school/schoolActiveData/teamDataInfo.vue'
import StudentDataInfo from '@/view/school/schoolActiveData/studentDataInfo.vue'
import BaseDataInfo from '@/view/base/baseActiveData/baseDataInfo.vue'
import StudentReportDataList from '@/view/school/schoolActiveData/studentReportDataList.vue'
import AgentEdit from '@/view/admin/adminAgent/agentEdit.vue'
import SettleRuleEdit from '@/view/admin/settlement/settleRuleEdit'
import InsureEdit from '@/view/admin/insure/insureEdit'
import InsureGoodsEdit from '@/view/admin/insure/insureGoodsEdit'
/* 新路由 */
import CheckOperators from '@/view/operators/checkOperators'
import CoursetorFiledTeachertorInfo from '@/view/admin/adminUserManage/coursetorFiledTeachertorInfo'
/* 用户移动端静态 */
import MobileLogin from '@/view/mobile/login.vue'
import MobileIndex from '@/view/mobile/index.vue'
import MobileScanQrcode from '@/view/mobile/scanQrcode.vue'
import ActivePayList from '@/view/school/schoolActive/activePayList'
export default createRouter({
    history:createWebHashHistory(),
    //动态注册路由
    routes: [
        {
            path: '/',
            name: '',
            component: Login
        },
        {
            path: '/autoLogin',
            name: 'AutoLogin',
            component: AutoLogin
        },
        {
            path: '/ADMIN',
            name: 'ADMIN',
            component: Login
        },
        {
            path: '/BASE',
            name: 'BASE',
            component: Login
        },
        {
            path: '/SCHOOL',
            name: 'SCHOOL',
            component: Login
        },
        {
            path: '/OPERATOR',
            name: 'OPERATOR',
            component: Login
        },
        {
            path:'/base/register',
            name: 'Register',
            component: Register
        },
        {
            path:'/base/checkBase',
            name: 'CheckBase',
            component: CheckBase
        },
        {
            path:'/operators/checkOperators',
            name:'CheckOperators',
            component:CheckOperators
        },
        {
            path:'/admin/adminUserManage/coursetorFiledTeachertorInfo',
            name:'CoursetorFiledTeachertorInfo',
            component:CoursetorFiledTeachertorInfo
        },
        {
            path:'/urban/checkUrban',
            name: 'CheckUrban',
            component: CheckUrban
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            redirect:'/index',
            children:[
                {
                    path: '/admin/adminSchool/schoolEdit',
                    name: 'SchoolEdit',
                    component: SchoolEdit
                },
                {
                    path: '/base/baseCourse/courseEdit',
                    name: 'CourseEdit',
                    component: CourseEdit
                },
                {
                    path: '/base/baseActive/activeBaseEdit',
                    name: 'ActiveBaseEdit',
                    component: ActiveBaseEdit
                },
                {
                    path: '/school/schoolActive/activeBaseInfo',
                    name: 'ActiveBaseInfo',
                    component: ActiveBaseInfo
                },
                {
                    path: '/base/activeApply/activeApplyInfo',
                    name: 'ActiveApplyInfo',
                    component: ActiveApplyInfo
                },
                {
                    path: '/base/baseUser/teacherEdit',
                    name: 'BaseTeacherEdit',
                    component: BaseTeacherEdit
                },
                {
                    path:'/school/schoolEva/evaBaseRate',
                    name:'EvaBaseRate',
                    component:EvaBaseRate
                },
                {
                    path:'/school/schoolManage/teamEdit',
                    name:'TeamEdit',
                    component:TeamEdit
                },
                {
                    path:'/school/schoolUser/teacherEdit',
                    name:'TeacherEdit',
                    component:TeacherEdit
                },
                {
                    path:'/school/schoolUser/studentEdit',
                    name:'StudentEdit',
                    component:StudentEdit
                },
                {
                    path:'/school/schoolManage/schoolEnjoyEdit',
                    name:'SchoolEnjoyEdit',
                    component:SchoolEnjoyEdit
                },
                {
                    path:'/admin/notice/noticeEdit',
                    name:'noticeEdit',
                    component:noticeEdit
                },
                {
                    path:'/base/order/orderList',
                    name:'orderList',
                    component:orderList
                },
                {
                    path:'/school/schoolActive/activePayList',
                    name:'ActivePayList',
                    component:ActivePayList
                },
                {
                    path:'/school/schoolActive/myActiveInfo',
                    name:'MyActiveInfo',
                    component:MyActiveInfo
                },
                {
                    path:'/school/schoolActive/baseInfo',
                    name:'BaseInfo',
                    component:BaseInfo
                },
                {
                    path:'/school/schoolActiveData/gradeAndTeamDataList',
                    name:'GradeAndTeamDataList',
                    component:GradeAndTeamDataList
                },
                {
                    path:'/school/schoolActiveData/teamDataInfo',
                    name:'TeamDataInfo',
                    component:TeamDataInfo
                },
                {
                    path:'/school/schoolActiveData/gradeDataInfo',
                    name:'GradeDataInfo',
                    component:GradeDataInfo
                },
                {
                    path:'/school/schoolActiveData/studentDataInfo',
                    name:'StudentDataInfo',
                    component:StudentDataInfo
                },
                {
                    path:'/school/schoolActiveData/schoolDataInfo',
                    name:'SchoolDataInfo',
                    component:SchoolDataInfo
                },
                {
                    path:'/base/baseActiveData/baseDataInfo',
                    name:'BaseDataInfo',
                    component:BaseDataInfo
                },
                {
                    path:'/school/schoolActiveData/studentReportDataList',
                    name:'StudentReportDataList',
                    component:StudentReportDataList
                },
                {
                    path:'/admin/adminAgent/agentEdit',
                    name:'AgentEdit',
                    component:AgentEdit
                },
                {
                    path:'/admin/settlement/settleRuleEdit',
                    name:'SettleRuleEdit',
                    component:SettleRuleEdit
                },
                {
                    path:'/admin/insure/insureEdit',
                    name:'InsureEdit',
                    component:InsureEdit
                },
                {
                    path:'/admin/insure/insureGoodsEdit',
                    name:'InsureGoodsEdit',
                    component:InsureGoodsEdit
                }
            ]
        },
        {
            path: '/mobile/login',
            name: 'MobileLogin',
            component: MobileLogin
        },
        {
            path: '/mobile/index',
            name: 'MobileIndex',
            component: MobileIndex
        },
        {
            path: '/mobile/scanQrcode',
            name: 'MobileSacnQrcode',
            component: MobileScanQrcode
        },
        {
            path: '/mobile/checkOrderInfo',
            name: 'CheckOrderInfo',
            component: CheckOrderInfo
        },
        {
            path: '/teacher/wxTeacherLogin',
            name: 'WxTeacherLogin',
            component: WxTeacherLogin
        },
        {
            path: '/teacher/teacherIndex',
            name: 'TeacherIndex',
            component: TeacherIndex
        },
        {
            path: '/teacher/menu',
            name: 'TeacherMenu',
            component: TeacherMenu
        },
        {
            path: '/teacher/person/personIndex',
            name: 'PersonIndex',
            component: PersonIndex
        },
        {
            path: '/teacher/person/teacherInfo',
            name: 'TeacherInfo',
            component: TeacherInfo
        },
        {
            path: '/teacher/active/teacherActiveList',
            name: 'TeacherActiveList',
            component: TeacherActiveList
        },
        {
            path: '/teacher/active/teacherActiveInfo',
            name: 'TeacherActiveInfo',
            component: TeacherActiveInfo
        },
        {
            path: '/teacher/teacherEva/teacherCourseList',
            name: 'TeacherCourseList',
            component: TeacherCourseList
        },
        {
            path: '/teacher/teacherEva/activeCourseInfo',
            name: 'ActiveCourseInfo',
            component: ActiveCourseInfo
        },
        {
            path: '/teacher/teacherEva/activeCourseEva',
            name: 'ActiveCourseEva',
            component: ActiveCourseEva
        },
        {
            path: '/dataBand/reportDetails',
            name: 'ReportDetails',
            component: ReportDetails
        },
        {
            path: '/dataBand/sharePoster',
            name: 'SharePoster',
            component: SharePoster
        },
    ]
})
