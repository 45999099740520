import axios from 'axios' // 引入
import {loginOut,isMobile} from "@/api/api";
import { ElMessage,ElLoading } from 'element-plus';//全局导入ele 消息
import {projectName} from '@/utils/config'
const config = {
    // 因为跨域了，所以这里如果写的话会自动拼接，会有两份，所以隐藏了
    timeout: 60000 // 设置最大请求时间
}
const _axios = axios.create(config);
var loading='';
// /* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
    config => {
        // 如果有需要在这里开启请求时的loading动画效果
        config.headers.Authorization = 'Bearer '+localStorage.getItem('token');  //添加token,需要结合自己的实际情况添加，
        if(!loading) {
            loading = ElLoading.service({
                lock: true,
                text: '加载中...',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        }
        return config;
    },
    err => Promise.reject(err)
)

// /* 请求之后的操作 */
_axios.interceptors.response.use((res) => {
        //console.log(res)
        // 在这里关闭请求时的loading动画效果
        // 这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
        // 一定结合自己的后端的返回代码进行操作
        // if (res.data.code === 401) {
        //   console.log('无权限操作')
        // }
    /*if(res.data.success){
        ElMessage({message: res.data.message,type:'success'});
    }*/
    if(res.data.state==401){//登录超时
        loginOut();
        ElMessage.error({message: res.data.msg});
        let re=isMobile();
        if(re){ location.replace("/#/mobile/login");}else {
            location.replace("/");
        }
    }
    setTimeout(res=>{
        loading.close();//最快0.3s后才能关闭
    },300)
    //loading.close();
    return res;
    },
    err => {
    //console.log(err);
        if (err) {
            // 在这里关闭请求时的loading动画效果
            ElMessage.error({message: '请求网络失败'});
        }
        loading.close();
        return Promise.reject(err)
    }
)

// 封装post,get方法
// 按理来说应该也可以封装其他的方法
const http = {
    get (url = '', params = {}) {
        url=projectName+url;
        loading='';
        return new Promise((resolve, reject) => {
            _axios({
                url,
                params,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                method: 'GET'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    getNoLoading (url = '', params = {}) {
        url=projectName+url;
        loading=ElLoading.service({
            lock: false,
            text: '',
            background: 'rgba(0, 0, 0, 0)',
        });
        return new Promise((resolve, reject) => {
            _axios({
                url,
                params,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                method: 'GET'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    post (url = '', params = {}) {
        url=projectName+url;
        loading='';
        return new Promise((resolve, reject) => {
            _axios({
                url,
                data: params,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                method: 'POST'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    put (url = '', params = {}) {
        url=projectName+url;
        loading='';
        return new Promise((resolve, reject) => {
            _axios({
                url,
                data: params,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                method: 'PUT'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    delete (url = '', params = {}) {
        url=projectName+url;
        loading='';
        return new Promise((resolve, reject) => {
            _axios({
                url,
                data: params,
                headers: { 'Content-Type': 'application/json;charset=UTF-8' },
                method: 'DELETE'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    file (url = '', params = {}) {
        url=projectName+url;
        loading='';
        return new Promise((resolve, reject) => {
            _axios({
                url,
                data: params,
                headers: { 'Content-Type': 'multipart/form-data' },
                method: 'POST'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    down(url = ''){
        let loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
        url='api'+url;
        location.href=url;
        loading.close();
    },
    downFile (url = '', params = {}) {
        url=projectName+url;
        loading='';
        return new Promise((resolve, reject) => {
            _axios({
                url,
                params,
                headers: {'Content-Type': 'application/x-download'},
                method: 'GET',
                responseType: 'blob'
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    }
}

// 暴露所写的内容
export default http

