import http from '@/utils/request.js'
export function login(data){
    return http.post('/currency/user_login/login',data)
}
export function autoLogin(data){
    return http.post('/currency/user_login/auto_login',data)
}
export function loginOut(){
    localStorage.clear();
}
export function getUserType(){//获取角色列表
    return http.get('/currency/common_user/get_user_type')
}
/* 修改密码 */
export function changePass(data){
    return http.post('/currency/common_user/change_password',data);
}

//获取当前登录用户详情
export function getInfo(){
    return http.get('/currency/common_method/info')
}
//获取id 和 用户类型 用户详情
export function getUserInfoByType(data){
    return http.get('/currency/common_method/user_info',data)
}
/* 获取用户列表分页 */
export function userPage(data){
    return http.get('/admin/admin_user/user_page',data)
}
export function userList(data){
    return http.get('/admin/admin_user/user_list',data)
}
//获取商户详情
export function getBazeInfo(data){
    return http.get('/baze/baze_user/base_info',data)
}
//保存 课程方、师资方、场地方 记录
export function editDieUser(data){
    return http.post('/admin/admin_user/die_user_edit',data)
}
//获取所有课程
export function courseList(data){
    return http.get('/admin/admin_course/list',data);
}
/* 审核用户信息 */
export function checkUser(data){
    return http.post('/admin/admin_user/check_user',data)
}
export function isMobile() {//判断当前设备是否是移动端
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
export function is_weixn() {//判断当前设备是否是微信端
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}
/* 首页 数据统计 */
export function indexData(type){
    return http.get('/currency/index_data/index_data'+type);
}
//首页数据
export function getIndexData(){//管理员
    return http.get('/admin/admin_index/admin_index_data')
}
export function getOperatorIndexData(){//运营商
    return http.get('/operator/operator_index/operator_index_data')
}
export function getServerIndexData(){//运营商
    return http.get('/baze/baze_index/server_index_data')
}
/* 获取待办事项 */
export function getCheckBazeNum(){
    return http.get('/admin/admin_baze/get_check_baze_num');
}
/* 查询分类列表 */
export function GoodsTypeList(data){
    return http.get('/baze/active_goods/list',data)
}
/* end */

/*
**************************************************************************************************************************************
 *  管理员
 *  供应商
 *  *******************************************************************************************************************************************
 */
export function adminPage(data){//获取
    return http.get('/admin/admin_user/admin_page',data)
}
export function editAdmin(data){//编辑
    return http.post('/admin/admin_user/admin_edit',data);
}
export function getIndustryList(data){//获取
    return http.get('/admin/admin_system/get_industry_list',data)
}
export function industryImport(data){//行业导入
    return http.file('/admin/admin_system/industry_import',data);
}
/* 学校  */
export function schoolPage(data){
    return http.get('/admin/admin_school/school_page',data);
}
export function editSchool(data){
    return http.post('/admin/admin_school/school_edit',data);
}
export function getSchoolNoticePage(data){//获取风采
    return http.get('/school/school_manage/notice_page',data)
}
export function editSchoolNotice(data){//获取风采
    return http.post('/school/school_manage/notice_edit',data)
}
export function getSchoolNoticeInfo(data){//获取风采
    return http.get('/school/school_manage/notice_info',data)
}
export function UpdateAllSchool(){
    return http.post('/admin/admin_school/async_school');
}
export function UpdateAll(){
    return http.post('/admin/admin_school/update_all_data');
}
export function getAllServerActivePage(data){//获取所有活动
    return http.get('/admin/admin_active/all_server_active_page',data)
}
export function getOrderStudentpage(data){//获取活动订单的学生
    return http.get('/admin/admin_order/order_student_page',data)
}
export function exportOrders(data){//导出活动订单
    return http.downFile('/order/order_manage/export_order',data)
}
/************************** 财务数据 *******************************************/
export function activeFinanceData(data){//获取产品财务数据
    return http.get('/finance/active_finance/active_finance_data',data);
}
export function activeFinanceOrder(data){//获取产品订单数据
    return http.get('/finance/active_finance/active_finance_order',data);
}
/* 年级 班级 */
export function getSchoolList(){//获取所有学校
    return http.get('/school/school_team/school_all_list')
}
export function getGradeAll(){//获取所有年级
    return http.get('/currency/common_method/grade_list')
}
export function getTeamList(){//年级结构获取当前学校的 班级
    return http.get('/school/school_team/team_list')
}
export function getTeamPage(data){//年级结构获取当前学校的 班级
    return http.get('/school/school_team/page',data)
}
export function teamEdit(data){//班级编辑
    return http.post('/school/school_team/edit',data)
}
export function UpdateAllTeam(data){
    return http.post('/school/school_team/async_school_team',data);
}
/* end */
/* 教师 */
export function TeacherPage(data){
    return http.get('/admin/admin_teacher/page',data);
}
export function getTeacherList(data){
    return http.get('/currency/common_user/teacher_list',data);
}
export function teacherEdit(data){
    return http.post('/admin/admin_teacher/edit',data);
}
export function UpdateAllTeacher(data){
    return http.post('/school/school_user/async_school_teacher',data);
}
/* end */
/* 教师 公众号 课外淘 */
export function wxTeacherLogin(data){//教师登录
    return http.post('/teacher/teacher_login/wx_login',data);
}
export function getJoinActiveStudentList(data){//教师评价获取参与活动的学生
    return http.get('/teacher/teacher_active_eva/get_join_active_student',data);
}
export function getActiveEvaCourseLinkTagList(data){//教师评价获取该环节所涉及到的标签
    return http.get('/teacher/teacher_active_eva/get_course_link_option_tag_list',data);
}
export function saveTeacherEva(data){//保存教师评价
    return http.post('/teacher/teacher_active_eva/save_teacher_eva',data);
}
export function getTeacherEva(data){//回显获取教师评价
    return http.get('/teacher/teacher_active_eva/get_teacher_eva',data);
}
/* 学生 */
export function StudentPage(data){
    return http.get('/admin/admin_student/student_page',data);
}
export function StudentEdit(data){
    return http.post('/school/school_user/edit',data);
}
export function UpdateAllStudent(data){
    return http.post('/school/school_user/async_school_student',data);
}
export function getTeacherActivePage(data){
    return http.get('/teacher/teacher_active_controller/teacher_active_page',data);
}
/* end */
/* 商户  */
export function basePage(data){
    return http.get('/admin/admin_baze/baze_page',data);
}
export function registerBase(data){
    return http.post('/currency/user_login/register',data)
}
export function editBase(data){
    return http.post('/admin/admin_baze/baze_edit',data)
}
export function checkBase(data){
    return http.post('/admin/admin_baze/check_baze',data)
}
export function base_wx_info(){//编辑商户详情信息
    return http.post('/baze/baze_user/base_wx_info')
}
export function base_wx_edit(data){//编辑商户详情信息
    return http.post('/baze/baze_user/base_wx_edit',data)
}
export function base_order_page(data){//商户订单
    return http.get('/baze/baze_order/page',data);
}
export function get_order_info(data){//校验后获取订单详情
    return http.get('/baze/baze_order/get_order_info',data);
}
export function base_order_check(data){//核销校验
    return http.post('/baze/baze_order/check_order',data);
}
export function base_order_check_ok(data){//确认核销
    return http.post('/baze/baze_order/check_order_ok',data);
}
export function sendBazeFamilyNotice(data){
    return http.post('/baze/baze_active/send_baze_family_notice',data)
}
export function setGoodsState(data){//商品上架和下架
    return http.post('/baze/baze_active/send_goods_state',data)
}
export function updateClickTime(){//更新消息点击时间
    return http.post('/baze/baze_user/baze_click_time')
}
export function getAddOrderNum(){
    return http.get('/baze/baze_order/get_add_order_num')
}
export function getMyUser(data){//获取我的用户
    return http.get('/baze/baze_user/get_my_user',data)
}
/* end */
/* 商户活动 */
export function baseActivePage(data){//获取我的用户
    return http.get('/baze/server_active_controller/my_server_page',data)
}
export function baseActiveInfo(data){//获取活动详情
    return http.get('/baze/server_active_controller/server_active_info',data)
}
export function serverActiveEdit(data){
    return http.post('/baze/server_active_controller/server_active_edit',data);
}
export function serverActiveRelease(data){//服务商活动发布
    return http.post('/baze/server_active_controller/server_active_release',data);
}
export function base_order_check_all(data){//商户端一键核销（核销后自动分组）
    return http.post('/baze/server_active_order/check_all_order',data);
}
export function base_active_Info_page(data){//查询活动情况列表
    return http.get('/baze/server_active_controller/server_active_page',data);
}
/* 商户活动数据 */
export function getBaseActiveData(data){
    return http.get('/baze/baze_data/baze_active_data',data);
}
/* 商户 审核学校活动通过后 在活动开始前可以修改课程指导教师 */
export function setCourseTeamTeacher(data){
    return http.post('/baze/baze_active/apply_active_set_teacher',data);
}
/* 课程 */
export function editCourse(data){
    return http.post('/admin/admin_course/edit',data)
}
export function coursePage(data){
    return http.get('/admin/admin_course/page',data);
}
export function courseListById(data){
    return http.get('/baze/baze_course/list_arr',data);
}
export function courseInfoById(data){
    return http.get('/admin/admin_course/info',data);
}
export function getEvaCourseLinkInfo(data){//获取课程信息及环节评价情况
    return http.get('/teacher/teacher_active_eva/get_eva_course_link_info',data);
}
export function checkTeacherEvaCourseState(data){//检查评价状态
    return http.post('/teacher/teacher_active_eva/check_eva_course_link_record',data);
}
export function changeTeacherEvaCourseState(data){//检查评价状态
    return http.post('/teacher/teacher_active_eva/change_eva_course_link_record',data);
}
/* end */
/* 活动产品 管理员 */

export function getActivePage(data){//列表
    return http.get('/admin/admin_active/page',data);
}
export function editActive(data){//管理员端活动产品编辑
    return http.post('/admin/admin_active/edit',data)
}
export function getActiveInfo(data){//详情
    return http.get('/admin/admin_active/info',data)
}
export function releaseAdminActive(data){//管理员端活动产品编辑
    return http.post('/admin/admin_active/admin_active_release',data)
}

export function baseActiveOfSchoolPage(data) {
    return http.get('/school/school_active/school_baze_active_page', data);
}
export function active_apply_page(data){//获取活动申请列表
    return http.get('/baze/baze_active/active_apply_page', data);
}
export function getSchoolActiveInfo(data){//获取活动申请详情
    return http.get('/baze/baze_active/get_school_active_info',data);
}
export function checkApplyActive(data){//审核
    return http.post('/baze/baze_active/check_apply_active',data)
}
export function insureList(){
    return http.get('/currency/common_method/insure_list');
}
export function active_all_page(data){//获取活动申请列表
    return http.get('/admin/admin_active/active_all_page', data);
}
export function getActiveCourseEva(data){//获取活动课程评价信息
    return http.get('/admin/active_course_eva_record/get_active_course_eva', data);
}
export function getActiveCourseLinkEva(data){//获取活动课程环节评价信息
    return http.get('/admin/active_course_eva_record/get_active_course_link_eva', data);
}
/* end */
/* 运营商活动 */
export function myOperatorPage(data) {//获取活动列表
    return http.get('/operator/operator_active_controller/my_operator_page', data);
}
export function myOperatorInfo(data) {//获取活动详情
    return http.get('/operator/operator_active_controller/info', data);
}
export function operatorActiveEdit(data) {//获取活动详情
    return http.post('/operator/operator_active_controller/operator_active_edit', data);
}
export function getOperatorActiveTeacher(data) {//获取运营商活动可选教师
    return http.get('/operator/operator_active_user_controller/teacher_list', data);
}
export function getOperatorActiveServer(data) {//获取运营商活动可选服务商
    return http.get('/operator/operator_active_user_controller/server_list', data);
}
export function setOperatorTeacher(data) {//设置教师
    return http.post('/operator/operator_active_controller/set_teacher', data);
}
export function setOperatorServer(data) {//设置教师
    return http.post('/operator/operator_active_controller/set_server', data);
}
export function getOperatorServerStudentPage(data) {//获取运营商下服务商的学生
    return http.get('/operator/operator_user/get_server_student_page', data);
}

/* 学校活动 */
export function applyActive(data){
    return http.post('/school/school_active/apply_active',data);
}
export function school_my_active_page(data){
    return http.get('/school/school_active/school_my_active_page',data);
}
export function sendSchoolActive(data){
    return http.post('/school/school_active/send_active',data);
}
export function school_active_pay_page(data){
    return http.get('/school/school_active/school_active_pay_page',data);
}
/* end */

/* 服务商活动 */
export function order_page(data){
    return http.get('/student/user_active_order/order_page',data);
}

export function getMenu(){
    return http.get('/admin/admin_menu/menu_all')
}
export function getMyMenu(){
    return http.get('/admin/admin_menu/role_menu')
}
export function addStudent(data,type='post'){
    return http[type]('/student',data)
}
export function addAsset(data,type='post'){
    return http[type]('/utensil',data)
}

/* 学校活动数据 */
export function getGradeTeamActiveObject(data){//获取单次活动参与对象 年级班级 分层
    return http.get('/school/school_data/get_grade_team_active_object',data)
}
export function getSchoolActiveData(data){//年级活动数据
    return http.get('/school/school_data/school_active_data',data)
}
export function getGradeActiveData(data){//年级活动数据
    return http.get('/school/school_data/grade_active_data',data)
}
export function getTeamActiveData(data){//班级活动数据
    return http.get('/school/school_data/team_active_data',data)
}
export function getStudentActiveData(data){//学生个人活动数据
    return http.get('/school/school_data/student_active_data',data)
}
export function getStudentJoinActive(data){//分页获取参与活动的学生名单
    return http.get('/school/school_data/get_student_join_active_page',data)
}
/* 报告 */
export function createStudentReport(data){//批量生成 或单个生成（根据是否传入学生id）
    return http.post('/student/student_report/active_student_report',data);
}
export function downAllActiveReport(data){//下载学生报告压缩包
    return http.get('/student/student_report/downLoadSchoolActiveZip',data);
}

/* 评价 */
export function schoolEvaBase(data){
    return http.post('/school/eva_school/save_eva',data)
}
export function getSchoolEvaBase(data){
    return http.get('/school/eva_school/get_eva',data)
}
export function baseEvaPage(data){
    return http.get('/baze/baze_eva/eva_page',data)
}

/* 能力标签 */
export function getEvaOption(data){
    return http.get('/currency/common_eva/option_list',data)
}
export function saveEvaOption(data){
    return http.post('/admin/admin_eva_option/option_save',data)
}

/* 运营商 */
export function editOperators(data){//新增/编辑
    return http.post('/operator/operator_user/edit',data)
}
export function getOperatorsInfo(data){//获取运营商中心详情
    return http.get('/operator/operator_user/info',data)
}
export function operatorsPage(data){
    return http.get('/admin/admin_user/operator_page',data);
}
export function getOperatorsList(data) {
    return http.get('/admin/admin_user/operator_list', data);
}

/* 代理商端 */
export function getAgentList(data){//获取列表 or 查询
    return http.getNoLoading('/agent/agent_manage/list',data);
}
export function getAgentPage(data){//分页获取
    return http.get('/admin/admin_agent/page',data);
}
export function agentInfo(data){//分页获取
    return http.get('/admin/admin_agent/info',data);
}
export function agentEdit(data){//分页获取
    return http.post('/admin/admin_agent/edit',data);
}
export function agentCheck(data){//分页获取
    return http.post('/admin/admin_agent/check',data);
}

/* 结算规则配置 */
export function getSettlementRulePage(data){//获取所有结算规则分页
    return http.get('/admin/settlement_rule/page',data)
}
export function editSettlementRule(data){//获取各大城市的结算规则列表
    return http.post('/admin/settlement_rule/edit',data)
}

/* 保险 */
export function getInsureList(data){//保险公司列表
    return http.get('/admin/admin_insure/insure_list',data)
}
export function getInsurePage(data){//保险公司列表
    return http.get('/admin/admin_insure/insure_page',data)
}
export function getInsureGoodsPage(data){//保险产品列表
    return http.get('/admin/admin_insure/insure_goods_page',data)
}
export function getInsureGoodsOberList(data){//保险产品列表按公司分类
    return http.get('/admin/admin_insure/insure_goods_ober_list',data)
}
export function editInsure(data){//保险公司编辑
    return http.post('/admin/admin_insure/insure_edit',data)
}
export function editGoodsInsure(data){//保险产品编辑
    return http.post('/admin/admin_insure/insure_goods_edit',data)
}

export function buyAllInsure(data){//一键购买保险
    return http.post('/insure/pingan_insure/insure_order',data)
}
export function refundInsure(data){//退保
    return http.post('/insure/pingan_insure/insure_refund',data)
}
export function showInsurePolicy(data){//查看保单
    return http.post('/insure/pingan_insure/insure_policy',data)
}

/* 活动、课程 兑换券 */
export function voucherPage(data){//获取列表（分页）
    return http.get('/voucher/voucher_controller/page',data)
}
export function getVoucherList(data){//获取列表
    return http.get('/voucher/voucher_controller/list',data)
}
export function voucherEdit(data){//兑换码券编辑
    return http.post('/voucher/voucher_controller/edit',data)
}


/* 管理员端 全局配置 */
export function systemConfig(){
    return http.get('/admin/admin_config/info')
}
export function editSystemConfig(data){
    return http.post('/admin/admin_config/edit',data)
}
export function getSettlementConfigPage(data){//获取各大城市的结算规则列表
    return http.get('/settlement/settlement_config/page',data)
}
export function setSettlementConfig(data){//批量设置结算规则
    return http.post('/settlement/settlement_config/set_one_city_settlement',data)
}
export function setAllSettlementConfig(data){//批量设置结算规则
    return http.post('/settlement/settlement_config/set_city_settlement',data)
}
export function getPosterList(data){//获取海报模板列表
    return http.get('/admin/share_poster_controller/list',data);
}
export function editPoster(data){//保存海报模板列表
    return http.post('/admin/share_poster_controller/edit',data);
}
export function deletePoster(data){//保存海报模板列表
    return http.post('/admin/share_poster_controller/delete_poster',data);
}
export function choosePoster(data){//保存海报模板列表
    return http.post('/admin/share_poster_controller/choose_poster',data);
}
/* 退款规则 */
export function refundRulePage(data){//退款规则列表（分页）
    return http.get('/admin/admin_refund_controller/page',data);
}
export function refundRuleList(data){//退款规则列表
    return http.get('/admin/admin_refund_controller/list',data);
}
export function refundRuleEdit(data){//退款规则编辑新增
    return http.post('/admin/admin_refund_controller/edit',data);
}
export function refundRuleStateChange(data){//退款规则状态修改
    return http.post('/admin/admin_refund_controller/change_state',data);
}
export function refundRuleOptionEdit(data){//退款规则细则编辑新增
    return http.post('/admin/admin_refund_controller/save_refund_rule_option',data);
}
/* 管理员退款 */
export function adminRefundOrder(data){//管理员主动退款
    return http.post('/admin/admin_order/admin_refund_order',data)
}
export function getRefundOrderPage(data){
    return http.get('/admin/admin_order/refund_order_page',data)
}
/* 结算 */

/* 公用方法 */
export function uploadFile(data){//上传图片
    return http.file('/currency/common_method/upload_file',data)
}
export function downFile(name){//下载模板
    return http.down('/'+name+'/download?Authorization=Bearer '+localStorage.getItem('token'));
}
export function importFile(data,url){
    return http.file(url,data)
}
export function exportFile(url){//下载
    return http.down(url);
}
export function sendNotice(data){//模板消息推送
    return http.post('/wx/wx_method/send_wx_notice',data)
}
export function sendBazeNotice(data){
    return http.post('/baze/baze_active/send_baze_notice',data)
}
/* 数据库操作 */
export function migrateDatabase(data){//数据库备份并 生成执行迁移文件 migrate
    return http.post('/currency/common_system/migrate_database',data)
}
export function DoingDatabase(data){//执行迁移文件（前提是需要修改）
    return http.post('/currency/common_system/doing_database',data)
}
/* 验证微信签名 */
export function getWxSign(data){
    return http.get('/wx/wx_method/get_wx_sign',data);
}
/* 发送短信 */
export function sendSms(data){
    return http.post('/currency/common_method/send_sms',data)
}
export function sendSmsCode(data){//发送验证码
    return http.post('/currency/common_method/send_sms_code',data)
}
//
export function collect_active(data){
    return http.post('/student/student_report/active_student_data',data)
}
// student/student_report/save_report_img    operator_server_active_id   student_id
export function save_report_img(data){//上传图片
    return http.file('/student/student_report/save_report_img',data)
}

export function crete_wx_qrcode(operator_server_active_id){
    return http.get('/student/student_active/create_wx_qrcode', { operator_server_active_id });
}
