import "core-js/modules/es.array.push.js";
import { StudentEdit, getTeamList } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      user_type: localStorage.getItem('userType'),
      Info: {
        id: '',
        name: '',
        username: ''
      },
      teamValue: [],
      teamList: [],
      rules: {
        username: [{
          required: true,
          message: "请输入教职工编号",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请输入教师名字",
          trigger: 'blur'
        }],
        team_id: [{
          required: true,
          message: "请输入选择年级班级",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.getTeamList();
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
      this.teamValue = [this.Info.team ? this.Info.team.gradeObject ? this.Info.team.gradeObject.code : '' : '', this.Info.team_id];
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    subData() {
      //提交
      if (this.teamValue.length == 0 || !this.teamValue[1]) {
        this.$root.ElMessage.error('请先选择年级班级');
        return false;
      }
      this.Info.team_id = this.teamValue[1];
      this.$refs['Info'].validate(valid => {
        if (valid) {
          StudentEdit(this.Info).then(res => {
            this.$root.resultMsg(res);
          });
        }
      });
    },
    getTeamList() {
      //获取年级班级树状结构数据格式化
      getTeamList().then(res => {
        let list = res.data;
        let data = [];
        for (let p in list) {
          let fd = {
            value: list[p].code,
            label: list[p].name,
            children: []
          };
          if (list[p].team.length < 1) {
            fd.children = undefined;
          }
          for (let j in list[p].team) {
            fd.children.push({
              value: list[p].team[j].id,
              label: list[p].team[j].name
            });
          }
          data.push(fd);
        }
        this.teamList = data;
      });
    }
  }
};