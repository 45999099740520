import menuDiv from "@/view/teacher/menu.vue";
import backImg from "@/static/img/teacher/person/user-back.png";
import defaultHead from '@/static/img/teacher/person/defaut-ava.png';
export default {
  components: {
    menuDiv
  },
  props: ['menuIndex'],
  data() {
    return {
      user: [],
      backImg: backImg,
      defaultHead: defaultHead
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    this.user = JSON.parse(localStorage.getItem('user')) || [];
    console.log(this.user);
    this.$refs.menuDiv.getIndex(2); //调用子组件方法
  },
  methods: {}
};