import "core-js/modules/es.array.push.js";
import { getStudentJoinActive, getGradeTeamActiveObject, getStudentReport, downAllActiveReport } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      gradeTeamList: [],
      teamList: [],
      pageInfo: {
        school_active_id: '',
        pageSize: 10,
        grade_code: '',
        team_id: '',
        student_name: '',
        current: 1,
        total: 0
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.school_active_id) {
      this.pageInfo.school_active_id = data.school_active_id;
      this.getGradeTeam();
      this.getList();
    }
  },
  methods: {
    reset() {
      this.pageInfo.student_name = '';
      this.pageInfo.grade_code = '';
      this.pageInfo.team_id = '';
      this.teamList = [];
    },
    search() {
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getStudentJoinActive(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getGradeTeam() {
      getGradeTeamActiveObject({
        school_active_id: this.pageInfo.school_active_id
      }).then(res => {
        this.gradeTeamList = res.data;
      });
    },
    chooseGrade(e) {
      this.gradeTeamList.find(res => {
        if (e == res.grade_code) {
          this.teamList = res.children;
        }
      });
      console.log(e);
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    getStudentReport() {
      this.$root.ElMessageBox.confirm('此操作将覆盖已生成的报告，该操作可能需要1~3分钟，需要您耐心等待，你确定要执行此操作吗？', '重要提示', {
        confirmButtonText: '继续执行',
        cancelButtonText: '暂不操作',
        type: 'warning',
        draggable: true
      }).then(() => {
        getStudentReport({
          school_active_id: this.pageInfo.school_active_id
        }).then(res => {
          this.search();
        });
      });
    },
    downAll() {
      downAllActiveReport({
        school_active_id: this.pageInfo.school_active_id
      }).then(res => {
        /*const blob = new Blob([res],{
          type:'application/zip'
        });*/
        const link = document.createElement("a");
        link.download = '活动学生报告'; //下载名字
        link.style.display = "none";
        link.href = this.$root.projectName + '/' + res; //URL.createObjectURL(blob);
        document.body.appendChild(link); // 将a标签追加到文档对象中
        link.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      });
    },
    showStudentData(student_id, name) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/studentDataInfo",
        query: {
          school_active_id: this.pageInfo.school_active_id,
          student_id: student_id,
          name: name
        }
      });
    }
  }
};