import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'; //全局导入ele 消息
import { useRouter, useRoute } from 'vue-router'; //路由跳转
import moment from 'moment';
import { getMyMenu, isMobile, getGradeAll, GoodsTypeList, sendNotice, uploadFile, getUserType } from "@/api/api";
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { projectName, STATELIST, BASETYPE, COURSELIST, ACTIVETYPE, CommentList, ACTIVELIST, PAY_STATE, STUDY_LEVEL, SETTYPE, ACTIVESTATETYPE, INSUREGOODSTYPELIST, COURSECOM } from "@/utils/config";
import city from "@/utils/city.json";
import addImg from "@/static/addImg.png";
import defaultImg from "@/static/img/active/default-img.png";
import defaultVideo from "@/static/img/active/default-video.png";
export default {
  components: {
    ElConfigProvider //展示中文
  },
  name: 'App',
  data() {
    //定义全局变量 其他组件使用 this.$root获取
    return {
      ElMessage: ElMessage,
      ElMessageBox: ElMessageBox,
      ElLoading: ElLoading,
      useRouter: useRouter(),
      useRoute: useRoute(),
      menuList: [],
      stateList: STATELIST,
      commentList: CommentList,
      locale: zhCn,
      cityList: city,
      gradeList: [],
      goodsTypeList: {
        1: [],
        2: [],
        all: []
      },
      defaultImg: defaultImg,
      defaultVideo: defaultVideo,
      addImg: addImg,
      projectName: projectName,
      userType: [],
      baseType: BASETYPE,
      courseType: COURSELIST,
      activeType: ACTIVETYPE,
      activeTypeList: ACTIVELIST,
      payType: PAY_STATE,
      studyLevel: STUDY_LEVEL,
      setType: SETTYPE,
      activeStateType: ACTIVESTATETYPE,
      insureGoodsTypeList: INSUREGOODSTYPELIST,
      courseCom: COURSECOM,
      moment: moment,
      showImageVisible: false,
      showImageUrl: ''
    };
  },
  mounted() {
    let eqment = this._isMobile();
    this.getUserType();
    if (!eqment && localStorage.getItem('token')) {
      this.getMenuList();
      this.getGradeList();
      //this.getGoodsTypeList();
    }
    if (eqment) {//移动端
    }
  },
  methods: {
    //定义全局方法 其他组件使用 this.$root获取
    _isMobile() {
      //判断当前设备是否是移动端
      return isMobile();
    },
    loadingOpen() {
      let loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return loading;
    },
    setRouter() {
      //动态添加路由
      for (let i in this.menuList) {
        let firstM = this.menuList[i];
        if (firstM.url) {
          let rout = {
            path: firstM.url,
            component: () => import('@/view' + firstM.url),
            name: firstM.name,
            meta: {
              title: firstM.title,
              noCache: true
            },
            children: []
          };
          this.useRouter.addRoute('Home', rout);
        } else if (firstM.child.length > 0) {
          for (let j in firstM.child) {
            let secondM = firstM.child[j];
            if (secondM.url) {
              let rout = {
                path: secondM.url,
                component: () => import('@/view' + secondM.url),
                name: secondM.name,
                meta: {
                  title: secondM.title,
                  noCache: true
                },
                children: []
              };
              this.useRouter.addRoute('Home', rout);
            }
          }
        }
      }
    },
    getMenuList() {
      getMyMenu().then(res => {
        this.menuList = res.data;
        let url = this.getFirstMenuUrl();
        this.setRouter(); //根据菜单注册组件
        setTimeout(() => {
          if (localStorage.getItem('menuUrl')) {
            this.$root.useRouter.push(localStorage.getItem('menuUrl'));
          } else {
            this.$root.useRouter.push(url);
          }
        }, 500);
      });
    },
    getFirstMenuUrl() {
      let url = '';
      for (let i in this.menuList) {
        if (this.menuList[i].path) {
          url = this.menuList[i].path;
          return url;
        } else {
          if (this.menuList[i].child.length > 0) {
            for (let j in this.menuList[i].child) {
              if (this.menuList[i].child[j].url) {
                return url;
              }
            }
          }
        }
        return url;
      }
    },
    getGradeList() {
      getGradeAll().then(res => {
        this.gradeList = res.data;
      });
    },
    getByName(name, name1, objectName, value) {
      //根据属性1获取属性2的值（name1 为空则返回对象）
      let v = '';
      this[objectName].find(res => {
        if (res[name] == value) {
          if (name1) {
            //返回对应属性的值
            v = res[name1];
          } else {
            //返回对象
            v = res;
          }
          return false;
        }
      });
      return v;
    },
    getByAreaCode(code1, code2, code3) {
      //根据省市区code获取 名称
      let text = '';
      let city = this.cityList;
      for (let p in city) {
        if (city[p].value == code1) {
          text = city[p].label;
          if (code2) {
            let city2 = city[p].children;
            for (let c in city2) {
              if (city2[c].value == code2) {
                text = text + ' ' + city2[c].label;
                if (code3) {
                  let city3 = city2[c].children;
                  for (let a in city3) {
                    if (city3[a].value == code3) {
                      text = text + ' ' + city3[a].label;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return text;
    },
    getCityCode(code, type) {
      // 根据code 查询城市 type: province 省码   city 直辖市码 area 区县码  '' 不确定
      let text = '';
      let city = this.cityList;
      for (let p in city) {
        if (!type || type == 'province') {
          if (city[p].value == code) {
            text = city[p].value;
            return text;
          }
        } else {
          let city2 = city[p].children;
          for (let c in city2) {
            if (!type || type == 'city') {
              if (city2[c].value == code) {
                text = city2[c].label;
                return text;
              }
            } else {
              let city3 = city2[c].children;
              for (let a in city3) {
                if (city3[a].value == code) {
                  text = city3[a].label;
                  return text;
                }
              }
            }
          }
        }
      }
      return text;
    },
    resultMsg(res) {
      //请求返回提示
      if (res.success) {
        this.ElMessage({
          type: 'success',
          message: res.msg
        });
        this.useRouter.go(-1);
      } else {
        this.ElMessage.error(res.msg);
      }
    },
    msgResult(res, url, objectPage, functionPage) {
      if (res.success) {
        this.ElMessage({
          type: 'success',
          message: res.msg
        });
        if (url) {
          //跳转
          this.useRouter.replace(url);
        } else if (objectPage && functionPage) {
          //调用组件方法
          //console.log(objectPage,functionPage)
          objectPage[functionPage]();
        } else {
          console.log('无效');
        }
      } else {
        this.ElMessage.error(res.msg);
      }
    },
    /* 通用对话框模板 */
    msgTalk(text, object, fnc, data) {
      this.ElMessageBox.confirm(text, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        if (object) {
          if (fnc) {
            object[fnc](data);
          }
        } else {
          if (fnc) {
            this.useRouter.push({
              path: fnc
            });
          }
        }
      });
    },
    loginOut() {
      let re = isMobile();
      let name = this.getByName('type', 'userType', 'userType', localStorage.getItem('userType'));
      localStorage.clear();
      if (re) {
        location.replace("/#/mobile/login");
      } else {
        location.replace("/");
      }
    },
    getGoodsTypeList() {
      //获取商品分类 并转化格式
      GoodsTypeList().then(res => {
        let data = [];
        let dataType1 = [];
        let dataType2 = [];
        let dataOne = [];
        for (let i in res.data) {
          let data1 = {
            value: res.data[i].code,
            label: res.data[i].name,
            children: []
          };
          dataOne.push({
            value: res.data[i].code,
            label: res.data[i].name,
            children: []
          }); //全部一级 可用于搜索
          for (let j in res.data[i].children) {
            data1.children.push({
              value: res.data[i].children[j].code,
              label: res.data[i].children[j].name,
              children: []
            });
          }
          if (res.data[i].type == 1) {
            dataType1.push(data1);
          }
          if (res.data[i].type == 2) {
            dataType2.push(data1);
          }
          data.push(data1);
        }
        this.goodsTypeList = {
          1: dataType1,
          2: dataType2,
          all: data,
          oneAll: dataOne
        };
      });
    },
    imgFileApi(arr) {
      for (let i in arr) {
        arr[i].url = this.projectName + '/' + arr[i].url;
      }
      return arr;
    },
    sendWxNotice() {
      sendNotice().then(res => {
        console.log(res);
      });
    },
    CopyUrl(data) {
      console.log(data);
      var oInput = document.createElement("input"); // 创建input标签存放地址
      oInput.value = data; // 给input标签赋值
      document.body.appendChild(oInput); // 将input标签添加到body里
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none"; // 隐藏input
      this.ElMessage({
        type: 'success',
        message: '复制成功'
      });
    },
    imageSrc(url) {
      //V3 动态引入图片
      let s = url;
      import(url).then(res => {
        s = res.default;
      });
      return s;
    },
    getUserType() {
      getUserType().then(res => {
        this.userType = res.data;
      });
    },
    //上传文件
    beforeAvatarUpload(e, object, name, type) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          if (type) {
            object[name].push({
              name: fileName,
              url: res.data
            });
          } else {
            object[name] = res.data;
          }
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    //上传图片
    imageUpload(e, object, name, type) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          if (type) {
            object[name].push({
              name: fileName,
              url: this.projectName + '/' + res.data
            });
          } else {
            object[name] = this.projectName + '/' + res.data;
          }
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    //放大查看图片
    showImage(url) {
      this.showImageUrl = url;
      this.showImageVisible = true;
    },
    //根据后台返回的文件流 页面下载文件或导出(默认excel)
    downUrlFile(fileData, name, type = 'application/vnd.ms-excel') {
      const blob = new Blob([fileData], {
        type: type
      });
      const link = document.createElement("a");
      link.download = name; //下载名字
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link); // 将a标签追加到文档对象中
      link.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
    },
    //保留小数点后num位
    toFixedNum(value, num) {
      value = value.toFixed(num);
      return value;
    },
    //身份信息脱敏
    idCardMasked(idCard) {
      if (idCard.length < 8) {
        return idCard;
      }
      return idCard.replace(/^(.{4})(.*)(.{4})$/, '$1**********$3'); // 使用正则表达式进行脱敏处理，这里示例只保留前后4位，中间用星号代替
    }
  }
};