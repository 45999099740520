import "core-js/modules/es.array.push.js";
import { applyActive, getTeamList, courseListById } from "@/api/api";
import userTip from "@/view/school/schoolUser/userTip.vue";
export default {
  name: "index",
  components: {
    userTip
  },
  data() {
    return {
      bazeInfo: [],
      cardIndex: '',
      Info: {
        active_id: '',
        active_date: '',
        day: '',
        team_ids: ''
      },
      teamList: [],
      checkTeamArr: [],
      courseList: [],
      rules: {
        start_time: [{
          required: true,
          message: "请选择开始时间",
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: "请选择结束时间",
          trigger: 'blur'
        }],
        day: [{
          required: true,
          message: "请输入活动天数",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.bazeInfo = JSON.parse(data.Info);
      this.Info.active_id = this.bazeInfo.id;
      this.bazeInfo.img_json = JSON.parse(this.bazeInfo.img_json);
      if (this.bazeInfo.process) {
        this.bazeInfo.process = JSON.parse(this.bazeInfo.process);
      } else {
        this.bazeInfo.process = [];
      }
      if (this.bazeInfo.cost_detail_text) {
        this.bazeInfo.cost_detail_text = JSON.parse(this.bazeInfo.cost_detail_text);
      } else {
        this.bazeInfo.cost_detail_text = [];
      }
      ;
      if (this.bazeInfo.course_json) {
        this.bazeInfo.course_json = JSON.parse(this.bazeInfo.course_json);
        this.getCourseListById();
      }
    }
    this.getTeamList();
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getTeamList() {
      getTeamList().then(res => {
        this.teamList = res.data;
      });
    },
    getCourseListById() {
      courseListById(this.bazeInfo.course_json).then(res => {
        this.courseList = res.data;
      });
    },
    checkTeam(id) {
      if (this.checkTeamArr.includes(id)) {
        this.checkTeamArr.splice(this.checkTeamArr.indexOf(id), 1);
      } else {
        this.checkTeamArr.push(id);
      }
    },
    changeCard(index) {
      this.cardIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
    allcheck() {
      //全选
      for (let i in this.teamList) {
        let gradeTeams = this.teamList[i].team;
        for (let j in gradeTeams) {
          this.checkTeam(gradeTeams[j].id);
        }
      }
      let gradeTeams = this.teamList[index].team;
      for (let i in gradeTeams) {
        this.checkTeam(gradeTeams[i].id);
      }
    },
    gradeAllcheck(index) {
      //全选年级
      let gradeTeams = this.teamList[index].team;
      for (let i in gradeTeams) {
        this.checkTeam(gradeTeams[i].id);
      }
    },
    subData() {
      //提交
      if (this.checkTeamArr.length < 1) {
        this.$root.ElMessage.error('请至少选择一个班级！');
        return false;
      }
      if (!this.Info.active_date) {
        this.$root.ElMessage.error('请至少选择活动申请日期！');
        return false;
      }
      this.Info.team_ids = this.checkTeamArr.join(',');
      this.Info.day = this.bazeInfo.max_day || 0;
      applyActive(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};