import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: $data.locale
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view), _createVNode(_component_el_dialog, {
      modelValue: $data.showImageVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.showImageVisible = $event)
    }, {
      default: _withCtx(() => [_createElementVNode("img", {
        "w-full": "",
        src: $data.showImageUrl,
        alt: "Preview Image",
        style: {
          "width": "100%"
        }
      }, null, 8, _hoisted_1)]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  }, 8, ["locale"]);
}