import "core-js/modules/es.array.push.js";
import { base_order_page } from "@/api/api";
export default {
  data() {
    return {
      index: 1,
      List: [],
      pageInfo: {
        pageSize: 5,
        current: 1,
        state: [2, 3],
        List: [],
        totalPage: 0,
        order_no: '',
        today_date: ''
      }
    };
  },
  created() {
    //this.getOrder();
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    this.setToday(new Date());
  },
  methods: {
    getOrder() {
      base_order_page(this.pageInfo).then(res => {
        this.pageInfo.totalPage = res.data.totalPage;
        this.List = this.List.concat(res.data.records);
      });
    },
    search() {
      this.List = [];
      this.pageInfo.current = 1;
      this.getOrder();
    },
    loadMore() {
      if (this.pageInfo.current < this.pageInfo.totalPage) {
        this.pageInfo.current = this.pageInfo.current + 1;
        this.getOrder();
      }
    },
    setToday(t) {
      this.pageInfo.today_date = t ? t.getTime() / 1000 : '';
      this.search();
    },
    goSao() {
      this.$root.useRouter.push({
        path: "/mobile/scanQrcode"
      });
    }
  }
};