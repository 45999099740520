import { serverActiveEdit, baseActiveInfo } from "@/api/api";
import { projectName } from "@/utils/config";
import editor from "@/components/editor.vue";
import editor1 from "@/components/editor1.vue";
export default {
  name: "index",
  components: {
    editor,
    editor1
  },
  data() {
    return {
      cityValue: [],
      goodsCodeValue: [],
      projectName: projectName,
      showImageVisible: false,
      showImageUrl: '',
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      insureValue: [],
      Info: {
        id: '',
        price: '',
        active_date: '',
        active_day: '',
        active_img_arr: []
      },
      activeInfo: [],
      type: 0,
      is_info: '',
      rules: {
        price: [{
          required: true,
          message: "请填写销售价",
          trigger: 'blur'
        }],
        active_date: [{
          required: true,
          message: "请选择活动日期",
          trigger: 'blur'
        }]
      }
    };
  },
  created() {},
  mounted() {
    let data = this.$root.useRoute.query;
    this.is_info = data.is_info;
    if (data.id) {
      this.Info.id = data.id;
      this.getInfo();
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    disabledDate(d) {
      if (this.activeInfo && this.activeInfo.time_frame) {
        let newd = this.$root.moment(d).format('YYYY-MM-DD');
        if (this.activeInfo.time_frame.includes(newd)) {
          return false;
        } //可选
      }
      return true; //不可选状态
    },
    getInfo() {
      baseActiveInfo({
        id: this.Info.id
      }).then(res => {
        this.Info = res.data;
        if (this.Info.active_img_arr) {
          this.Info.active_img_arr = JSON.parse(this.Info.active_img_arr);
          for (let i in this.Info.active_img_arr) {
            let s = this.Info.active_img_arr[i].url;
            let sarr = s.split(this.$root.projectName + '/');
            if (!sarr[1]) {
              this.Info.active_img_arr[i].url = '/' + this.$root.projectName + '/' + s;
            }
          }
        } else {
          this.Info.active_img_arr = [];
        }
        if (this.Info.operatorActive) {
          this.activeInfo = res.data.operatorActive;
          if (this.activeInfo.operator_file_path_arr) {
            this.activeInfo.operator_file_path_arr = JSON.parse(this.activeInfo.operator_file_path_arr);
          } else {
            this.activeInfo.operator_file_path_arr = [];
          }
          if (this.activeInfo.time_frame) {
            this.activeInfo.time_frame = JSON.parse(this.activeInfo.time_frame);
          } else {
            this.activeInfo.time_frame = [];
          }
        }
        console.log(this.Info);
      });
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (this.Info.active_img_arr) {
            for (let i in this.Info.active_img_arr) {
              let s = this.Info.active_img_arr[i].url;
              let sarr = s.split(this.$root.projectName + '/');
              if (sarr[1]) {
                this.Info.active_img_arr[i].url = sarr[1];
              }
            }
          }
          serverActiveEdit(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    setText() {
      this.$refs.editor1.setText(this.Info.sign_reading);
      this.$refs.editor.setText(this.Info.active_process);
    },
    getText(html) {
      this.Info.active_process = html;
    },
    getText1(html) {
      this.Info.sign_reading = html;
    }
  }
};