import "core-js/modules/es.array.push.js";
import { wxTeacherLogin } from "@/api/api";
export default {
  data() {
    return {
      Info: [],
      code: '1231'
    };
  },
  created() {},
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    this.code = data.code;
    if (this.code) {
      this.wxLogin();
    }
  },
  methods: {
    wxLogin() {
      wxTeacherLogin({
        code: this.code
      }).then(res => {
        if (res.success) {
          window.localStorage.setItem('token', res.data2);
          window.localStorage.setItem('userType', res.data.user_type);
          window.localStorage.setItem('user', JSON.stringify(res.data));
          this.$root.useRouter.push("/teacher/teacherIndex");
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    }
  }
};