import { get_order_info, base_order_check_ok } from "@/api/api";
export default {
  data() {
    return {
      Info: [],
      order_no: ''
    };
  },
  created() {
    //this.snmUrl();
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    if (data.order_no) {
      this.order_no = data.order_no;
      this.getOrderInfo();
    } else {
      this.$root.ElMessage.error({
        message: '无效的核销码'
      });
    }
  },
  methods: {
    getOrderInfo() {
      get_order_info({
        order_no: this.order_no
      }).then(res => {
        this.Info = res.data;
      });
    },
    checkOrder() {
      this.$root.ElMessageBox.confirm('请核对订单及用户信息，确认无误后核销，码券核销后无法退还，你确认要核销吗?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        base_order_check_ok({
          order_no: this.order_no
        }).then(res => {
          this.$root.msgResult(res, '/mobile/index');
        });
      });
    }
  }
};