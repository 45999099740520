import "core-js/modules/es.array.push.js";
import { baseActiveInfo } from "@/api/api";
export default {
  data() {
    return {
      Info: []
    };
  },
  created() {},
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.Info.id = data.id;
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      baseActiveInfo({
        id: this.Info.id
      }).then(res => {
        this.Info = res.data;
      });
    },
    activeCourseInfo(id) {
      this.$root.useRouter.push({
        path: "/teacher/teacherEva/activeCourseInfo",
        query: {
          id: id,
          operator_server_active_id: this.Info.id
        }
      });
    }
  }
};