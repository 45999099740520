export default {
  name: 'ImageShow2',
  props: {
    fit: {
      type: String,
      default() {
        return 'fill';
      }
    },
    imageWidthFill: {
      type: Boolean,
      default() {
        return false;
      }
    },
    src: {
      type: String,
      default() {
        return '';
      }
    },
    width: {
      type: String,
      default() {
        return '160';
      }
    },
    height: {
      type: String,
      default() {
        return '66';
      }
    },
    bg: {
      type: Boolean,
      default() {
        return false;
      }
    },
    preview: {
      type: Boolean,
      default() {
        return true;
      }
    },
    br: {
      type: String,
      default() {
        return '0';
      }
    }
  },
  data() {
    return {
      style: {}
    };
  },
  computed: {
    isVideo() {
      const suffixList = ['.mp4', '.mov', '.m4v', '.3gp', '.avi', '.m3u8', '.webm'];
      const currResourcesSuffix = '.' + this.src.split('.')[this.src.split('.').length - 1];
      if (currResourcesSuffix) {
        return suffixList.includes(currResourcesSuffix.toLowerCase());
      }
      return false;
    }
  },
  created() {
    this.style = {
      width: `${this.width}px`,
      height: `${this.height}px`,
      backgroundColor: this.bg ? '#ccc' : '',
      borderRadius: `${this.br}px`
    };
  },
  methods: {
    /**
     * 点击删除事件
     */
    deleteHandler() {
      this.$emit('deleteImage', {
        img: this.src
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      const deleteIcon = document.getElementById('deleteIcon');
      const deleteIconOuter = document.getElementById('deleteIconOuter');
      //鼠标移入事件
      if (deleteIcon) {
        deleteIcon.onmouseover = () => {
          deleteIconOuter && deleteIconOuter.classList.add("over-class");
        };

        //鼠标移出事件
        deleteIcon.onmouseout = () => {
          deleteIconOuter && deleteIconOuter.classList.remove("over-class");
        };
      }
    });
  }
};