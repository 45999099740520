import { editGoodsInsure, getInsureList } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      Info: {
        id: '',
        name: '',
        ins_code: '',
        insure_code: ''
      },
      insureList: [],
      rules: {
        name: [{
          required: true,
          message: "保险产品名称不能为空",
          trigger: 'blur'
        }],
        ins_code: [{
          required: true,
          message: "保险公司编号不能为空",
          trigger: 'blur'
        }],
        insure_code: [{
          required: true,
          message: "产品编号不能为空",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
    }
    this.getInsureList();
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInsureList() {
      getInsureList().then(res => {
        this.insureList = res.data;
      });
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          editGoodsInsure(this.Info).then(res => {
            this.$root.msgResult(res, '/admin/insure/insureGoodsList');
          });
        }
      });
    }
  }
};