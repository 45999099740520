import "core-js/modules/es.array.push.js";
import { baseActiveInfo } from "@/api/api.js";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      Info: {
        id: ''
      },
      stateList: this.$root.stateList
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    this.Info.id = data.id;
    this.getInfo();
  },
  methods: {
    getInfo() {
      baseActiveInfo(this.Info).then(res => {
        console.log(res);
        this.Info = res.data;
        if (this.Info && this.Info.active && this.Info.active.active_img_arr) {
          this.Info.active.active_img_arr = JSON.parse(this.Info.active.active_img_arr);
        }
      });
    },
    goActiveInfo(id) {
      this.$root.useRouter.push({
        path: "/teacher/active/teacherActiveInfo",
        query: {
          id: id
        }
      });
    }
  }
};