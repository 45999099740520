import {projectName} from "@/utils/config";
import {Base64} from "js-base64";

export const viewImage = link => {
    const url = showImage(link)
    // uni.previewImage({
    //     urls: [url],
    //     current: url
    // })
}

export const viewImages = (link, links) => {
    const url = showImage(link)
    const urls = []
    links.forEach(iter => urls.push(showImage(iter)))
    uni.previewImage({
        current: url,
        urls
    })
}

export const showImage = img => `${projectName}/${img}`

export const isImage = name => {
    const suffixs = [
        '.xbm','.tif','pjp','.svgz',
        'jpg','jpeg','ico','tiff',
        '.gif','svg','.jfif',
        '.webp','.png','.bmp',
        'pjpeg','.avif'
    ]
    let res = false
    suffixs.forEach(iter => {
        if (name.toUpperCase().endsWith(iter.toUpperCase())) {
            res = true
        }
    })
    return res
}

export const numberToChinese = num => {
    if (typeof num !== 'number' || isNaN(num) || num < 0 || num > 99999) {
        return "错误：请输入0到99999之间的整数";
    }

    const chineseDigits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const chinesePlaceValues = ['', '十', '百', '千', '万'];

    let chineseStr = '';
    let digits = num.toString();
    for (let i = 0; i < digits.length; i++) {
        const digit = parseInt(digits[i], 10);
        const placeValue = chinesePlaceValues[digits.length - i - 1];
        if (digit === 0 && placeValue === '') {
            continue;
        } else if (digit === 0 && chineseStr !== '') {
            chineseStr += '零';
        } else {
            chineseStr += chineseDigits[digit] + (placeValue || '');
        }
    }

    // 处理连续的“零”和末尾的“零”
    chineseStr = chineseStr.replace(/零+/g, '零');
    chineseStr = chineseStr.replace(/零(十|百|千)/g, '$1');
    chineseStr = chineseStr.replace(/^一十/, '十');

    return chineseStr;
}


/**
 * base64转blob
 * @param code
 * @returns {Blob}
 */
export const base64ToBlob = code => {
    const parts = code.split(';base64,')
    const contentType = parts[0].split(':')[1]
    const raw = window.atob(parts[1])
    const rawLength = raw.length
    const uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: contentType })
}

export const base64ToBlobNoAtob = (base64) => {
    // 分割MIME类型和数据
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const base64Data = base64.split(',')[1];

    // 使用js-base64解码Base64字符串
    const byteCharacters = Base64.atob(base64Data);

    // 创建一个包含二进制数据的Uint8Array
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // 创建Blob对象
    const blob = new Blob([byteArray], { type: mimeString });
    return blob;
}

/**
 * blob转file
 * @param blob
 * @returns {File}
 */
export const blobToFile = blob => {
    return new File([blob], 'file', {type: blob.type, lastModified: Date.now()});
}

export const base64ToFile = (val) => {
    return blobToFile(base64ToBlob(val))
}

export const base64ToFileNoAtob = (val) => {
    return blobToFile(base64ToBlobNoAtob(val))
}

export const base64ImgToFile = (val) => {
    const blob = base64ToBlob(val)
    return new File([blob], 'file.png', {type: blob.type, lastModified: Date.now()});
}
