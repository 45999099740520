import "core-js/modules/es.array.push.js";
import { schoolEvaBase, getSchoolEvaBase, uploadFile } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      Info: {
        school_active_id: '',
        img_json: []
      },
      isAble: true
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.Info.school_active_id = data.id;
      this.getInfo();
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInfo() {
      getSchoolEvaBase({
        school_active_id: this.Info.school_active_id
      }).then(res => {
        if (res.data) {
          this.Info = res.data;
          this.isAble = false;
          if (this.Info.img_json) {
            this.Info.img_json = JSON.parse(this.Info.img_json);
          } else {
            this.Info.img_json = [];
          }
        }
      });
    },
    beforeAvatarUpload(e, name) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          if (name == 'img_json' || name == 'process') {
            this.Info[name].push({
              name: fileName,
              url: this.$root.projectName + '/' + res.data
            });
          } else {
            this.Info[name] = res.data;
          }
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    subData() {
      //提交
      schoolEvaBase(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.go(-1);
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};