import "core-js/modules/es.array.push.js";
import { getEvaOption, getJoinActiveStudentList, saveTeacherEva, getActiveEvaCourseLinkTagList, getTeacherEva, checkTeacherEvaCourseState, changeTeacherEvaCourseState } from "@/api/api.js";
export default {
  data() {
    return {
      Info: [],
      optionList: [],
      activeStudentList: [],
      operator_server_active_id: '',
      my_drawer: false,
      evaIndex: -1,
      selectAZ: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      selectedABC: [],
      teacherEvaData: [],
      teacherEvaFileArr: []
    };
  },
  created() {},
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    window.addEventListener('beforeunload', this.clearEva);
    this.getOptions();
    let data = this.$root.useRoute.query;
    this.operator_server_active_id = data.operator_server_active_id;
    this.getStudentList();
    if (data.info) {
      this.Info = JSON.parse(data.info);
      this.checkEvaState(this.operator_server_active_id, this.Info.id);
      this.setAllTag(this.Info.course_link_eva);
    }
  },
  methods: {
    getOptions() {
      getEvaOption().then(res => {
        this.optionList = res.data;
      });
    },
    checkEvaState(operator_server_active_id, id) {
      //检验评价状态
      checkTeacherEvaCourseState({
        operator_server_active_id: this.operator_server_active_id,
        id: id
      }).then(res => {
        if (!res.success) {
          this.$root.ElMessage.error(res.msg);
          this.$root.useRouter.replace({
            path: "/teacher/teacherEva/activeCourseInfo",
            query: {
              id: this.Info.course_id,
              operator_server_active_id: this.operator_server_active_id
            }
          });
        }
      });
    },
    setAllTag(course_link_eva) {
      let tags = [];
      for (let i in course_link_eva) {
        if (course_link_eva[i]) {
          for (let j in course_link_eva[i]) {
            if (course_link_eva[i][j]) {
              tags.push({
                option_id: i,
                tag_name: course_link_eva[i][j]
              });
            }
          }
        }
      }
      this.tagList = tags;
    },
    resetTeacherEvaFileArr(activeStudentList) {
      //初始化 文件
      for (let i in activeStudentList) {
        this.teacherEvaFileArr[activeStudentList[i].student_id] = {
          file_path_arr: []
        };
      }
      this.getTeacherEva();
    },
    getStudentList() {
      getJoinActiveStudentList({
        operator_server_active_id: this.operator_server_active_id
      }).then(res => {
        this.activeStudentList = res.data;
        this.resetTeacherEvaFileArr(this.activeStudentList); //初始化 文件
      });
    },
    getActiveEvaCourseLinkTagList() {
      getActiveEvaCourseLinkTagList().then(res => {
        this.tagList = res.data;
      });
    },
    showStudentDiv(studentId) {
      this.evaIndex = studentId;
      this.my_drawer = true;
    },
    cancelClick() {
      this.my_drawer = false;
    },
    chooseEvaTag(tagName, studentId) {
      if (!this.teacherEvaData[studentId]) {
        this.teacherEvaData[studentId] = [];
      }
      if (this.teacherEvaData[studentId].includes(tagName)) {
        this.teacherEvaData[studentId].splice(this.teacherEvaData[studentId].indexOf(tagName), 1);
      } else {
        this.teacherEvaData[studentId].push(tagName);
      }
    },
    getStudentName(id) {
      let name = '';
      this.activeStudentList.find(res => {
        console.log(id + "=" + res.student_id);
        if (id == res.student_id) {
          console.log(res.student_name + "=" + res.student_id);
          name = res.student_name;
        }
      });
      return name;
    },
    getTeacherEva() {
      getTeacherEva({
        operator_server_active_id: this.operator_server_active_id,
        course_link_id: this.Info.id
      }).then(res => {
        if (res.success && res.data) {
          if (res.data.teacherEvaData) {
            this.teacherEvaData = res.data.teacherEvaData;
          }
          if (res.data.teacherEvaFileArr) {
            for (let x in res.data.teacherEvaFileArr) {
              if (res.data.teacherEvaFileArr[x] && !res.data.teacherEvaFileArr[x].file_path_arr) {
                res.data.teacherEvaFileArr[x].file_path_arr = [];
              }
              this.teacherEvaFileArr[x] = res.data.teacherEvaFileArr[x];
            }
            //this.teacherEvaFileArr=res.data.teacherEvaFileArr;
          }
        }
        console.log(this.teacherEvaFileArr);
      });
    },
    activeCourseInfo(id) {
      this.$root.useRouter.push({
        path: "/teacher/teacherEva/activeCourseInfo",
        query: {
          id: id
        }
      });
    },
    selectName(a) {
      //滚动至ref 指定的位置
      let el = this.$refs[a];
      this.selectedABC = [];
      this.selectedABC[a] = 'error';
      if (el && el[0]) {
        el[0].scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    subData() {
      saveTeacherEva({
        operator_server_active_id: this.operator_server_active_id,
        course_link_id: this.Info.id,
        teacherEvaData: this.teacherEvaData,
        teacherEvaFileArr: this.teacherEvaFileArr
      }).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.replace({
            path: "/teacher/teacherEva/activeCourseInfo",
            query: {
              id: this.Info.course_id,
              operator_server_active_id: this.operator_server_active_id
            }
          });
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    clearEva() {
      //取消评价
      changeTeacherEvaCourseState({
        operator_server_active_id: this.operator_server_active_id,
        id: this.Info.id
      }).then(res => {});
    }
  },
  unmounted() {
    this.clearEva();
  }
};