import { agentEdit, agentInfo, agentCheck } from "@/api/api";
import { stateList } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      cityValue: [],
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      Info: {
        id: '',
        name: '',
        code: ''
      },
      stateList: stateList,
      adminList: [],
      levelList: [],
      type: 0,
      isShenhe: true,
      checkMuneSer: [],
      rules: {
        phone: [{
          required: true,
          message: "电话号码不能为空",
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: "密码不能为空",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请输入代理商名称",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.Info.id = data.id;
      this.getInfo();
      //this.cityValue=[parseInt(this.Info.province),parseInt(this.Info.city),parseInt(this.Info.area)];//回显省市区县
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    getInfo() {
      agentInfo({
        id: this.Info.id
      }).then(res => {
        this.Info = res.data;
      });
    },
    checkAgent(state) {
      this.Info.state = state;
      agentCheck({
        id: this.Info.id,
        state: state
      }).then(res => {
        this.$root.resultMsg(res);
      });
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (!this.Info.password) {
            this.$root.ElMessage.error('密码不能为空');
          }
          agentEdit(this.Info).then(res => {
            this.$root.resultMsg(res);
          });
        }
      });
    }
  }
};