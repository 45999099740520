import "core-js/modules/es.array.push.js";
import { autoLogin } from "@/api/api";
export default {
  data() {
    return {
      Info: []
    };
  },
  created() {},
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    this.Info = {
      username: data.username,
      ticket: data.ticket,
      nonce: data.nonce,
      timestamp: data.timestamp
    };
    this.autoLogin();
  },
  methods: {
    autoLogin() {
      //学校
      autoLogin(this.Info).then(res => {
        if (res.success) {
          window.localStorage.setItem('token', res.data2);
          window.localStorage.setItem('userType', res.data.user_type);
          window.localStorage.setItem('user', JSON.stringify(res.data));
          this.$root.getMenuList();
          this.$root.ElMessage({
            message: res.msg,
            type: 'success'
          });
          this.$root.useRouter.push("/index" + res.data.user_type); //跳转至对应角色的首页
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
          this.$root.useRouter.push("/SCHOOL"); //跳转至对应角色的首页
        }
      });
    }
  }
};