import "core-js/modules/es.array.push.js";
import { getGradeTeamActiveObject } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      school_active_id: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.school_active_id) {
      this.school_active_id = data.school_active_id;
      this.getList();
    }
  },
  methods: {
    getList() {
      getGradeTeamActiveObject({
        school_active_id: this.school_active_id
      }).then(res => {
        console.log(res);
        this.tableData = res.data;
      });
    },
    teamData(team_id) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/teamDataInfo",
        query: {
          school_active_id: this.school_active_id,
          team_id: team_id
        }
      });
    },
    gradeData(grade_code) {
      this.$root.useRouter.push({
        path: "/school/schoolActiveData/gradeDataInfo",
        query: {
          school_active_id: this.school_active_id,
          grade_code: grade_code
        }
      });
    }
  }
};