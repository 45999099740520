import { editInsure } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      Info: {
        id: '',
        name: '',
        ins_code: ''
      },
      rules: {
        name: [{
          required: true,
          message: "保险公司名称不能为空",
          trigger: 'blur'
        }],
        ins_code: [{
          required: true,
          message: "保险公司编号不能为空",
          trigger: 'blur'
        }],
        api_url: [{
          required: true,
          message: "接口地址不能为空",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          editInsure(this.Info).then(res => {
            this.$root.msgResult(res, '/admin/insure/insureList');
          });
        }
      });
    }
  }
};