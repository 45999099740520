import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-45f77798"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "text-out-hide"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_empty = _resolveComponent("el-empty");
  return _openBlock(), _createElementBlock("div", null, [$data.Info && $data.Info.operatorActive && $data.Info.operatorActive.active && $data.Info.operatorActive.active.courseList && $data.Info.operatorActive.active.courseList.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.Info.operatorActive.active.courseList, (c, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "text-left div-course flex align-center justify-between"
    }, [_createElementVNode("div", _hoisted_2, _toDisplayString(c.course_name), 1), _createElementVNode("div", {
      style: {
        "color": "#409eff"
      },
      onClick: $event => $options.activeCourseInfo(c.course_id)
    }, "评价", 8, _hoisted_3)]);
  }), 128))])) : (_openBlock(), _createBlock(_component_el_empty, {
    key: 1,
    description: "该活动暂无课程"
  }))]);
}