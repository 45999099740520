import "core-js/modules/es.array.push.js";
import { uploadFile, editDieUser, getUserInfoByType, systemConfig } from "@/api/api";
import { projectName } from "@/utils/config";
import defaultImg from "@/static/addImg.png";
export default {
  data() {
    return {
      toUrl: '',
      cityValue: [],
      roleInfo: [],
      userType: localStorage.getItem('userType'),
      Info: {},
      type: '',
      baseImgArr: [{
        v: 'img_yy',
        n: '营业执照'
      }, {
        v: 'img_jy',
        n: '经营许可证'
      }, {
        v: 'img_sw',
        n: '税务登记证'
      }, {
        v: 'img_frz',
        n: '法人身份证正面'
      }, {
        v: 'img_frf',
        n: '法人身份证反面'
      }, {
        v: 'img_xf',
        n: '消防许可证'
      }, {
        v: 'img_bx',
        n: '商户保险单'
      }, {
        v: 'img_cy',
        n: '餐饮服务许可证、卫生许可证'
      }, {
        v: 'img_pp',
        n: '平面图'
      }, {
        v: 'img_qm',
        n: '商户全貌'
      }, {
        v: 'img_dm',
        n: '大门'
      }, {
        v: 'img_dl',
        n: '主要道路'
      }, {
        v: 'img_bgs',
        n: '商户办公室'
      }, {
        v: 'img_jxl',
        n: '教学楼通道'
      }, {
        v: 'img_tcc',
        n: '停车场'
      }, {
        v: 'img_js',
        n: '教室或多功能室、卫生许可证'
      }],
      projectName: projectName,
      default_img: defaultImg,
      loading: false,
      agentList: [],
      rules: {
        name: [{
          required: true,
          message: "请输入商户名称",
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: "请选择商户类型",
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: "请输入联系电话",
          trigger: 'blur'
        }],
        year: [{
          required: true,
          message: '请输入建成年份',
          trigger: 'blur'
        }],
        fanwei: [{
          required: true,
          message: '请输入经营范围',
          trigger: 'blur'
        }],
        ziben: [{
          required: true,
          message: '请输入注册资本',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: "统一信用代码",
          trigger: 'blur'
        }],
        yingyeqixian: [{
          required: true,
          message: "营业期限",
          trigger: 'blur'
        }],
        legal_person: [{
          required: true,
          message: "法人姓名",
          trigger: 'blur'
        }],
        id_no: [{
          required: true,
          message: "请输入法人身份证",
          trigger: 'blur'
        }],
        bank_name: [{
          required: true,
          message: "请输入开户银行名称",
          trigger: 'blur'
        }],
        bank_address: [{
          required: true,
          message: "请输入开户银行地址",
          trigger: 'blur'
        }],
        bank_no: [{
          required: true,
          message: "请输入开户银行账号",
          trigger: 'blur'
        }],
        img_changsuo_arr: [{
          required: true,
          message: "请上传经营场所实况图片",
          trigger: 'blur'
        }],
        level: [{
          required: true,
          message: '请输入纳税信用等级',
          trigger: 'blur'
        }],
        img_yy: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'blur'
        }],
        img_frz: [{
          required: true,
          message: '请上传法人身份证正面',
          trigger: 'blur'
        }],
        img_frf: [{
          required: true,
          message: '请上传法人身份证反面',
          trigger: 'blur'
        }],
        province: [{
          required: true,
          message: '请选择地区',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let data = this.$root.useRoute.query;
    this.systemConfigInfo();
    if (data.user_type) {
      this.roleInfo = this.$root.getByName('type', '', 'userType', data.user_type);
      this.Info.table_name = this.roleInfo.table;
    }
    if (data.id) {
      this.getInfo(data.id);
    }
    if (data.toUrl) {
      this.toUrl = data.toUrl;
    }
  },
  methods: {
    getInfo(id) {
      getUserInfoByType({
        id: id,
        table_name: this.roleInfo.table
      }).then(res => {
        this.Info = res.data;
        this.Info.table_name = this.roleInfo.table;
        this.cityValue = [parseInt(this.Info.province), parseInt(this.Info.city), parseInt(this.Info.area)]; //回显省市区县
        this.reflexData();
      });
    },
    systemConfigInfo() {
      systemConfig().then(res => {
        if (res.data) {
          this.systemInfo = res.data;
        }
      });
    },
    reflexData() {
      if (this.Info.img_hangye_arr) {
        this.Info.img_hangye_arr = JSON.parse(this.Info.img_hangye_arr);
      } else {
        this.Info.img_hangye_arr = [];
      }
      if (this.Info.img_changsuo_arr) {
        this.Info.img_changsuo_arr = JSON.parse(this.Info.img_changsuo_arr);
      } else {
        this.Info.img_changsuo_arr = [];
      }
    },
    beforeAvatarUpload(e, name) {
      let formData = new FormData();
      let fileName = e.name;
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          if (name == 'img_insure_arr' || name == 'img_hangye_arr' || name == 'img_changsuo_arr' || name == 'anquan_file_arr' || name == 'yingji_file_arr') {
            this.Info[name].push({
              name: fileName,
              url: this.projectName + '/' + res.data
            });
          } else {
            this.Info[name] = res.data;
          }
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    areaChange(e) {
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    qxBank() {
      if (this.toUrl) {
        this.$root.useRouter.replace(this.toUrl);
      } else {
        this.$root.useRouter.go(-1);
      }
    },
    onSubmit() {
      this.$refs['Info'].validate(valid => {
        if (valid) {
          editDieUser(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                message: res.msg,
                type: 'success'
              });
              this.$root.useRouter.replace(this.toUrl);
            } else {
              this.$root.ElMessage.error({
                message: res.msg
              });
            }
          });
        }
      });
    }
  }
};