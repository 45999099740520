import { registerBase, systemConfig, sendSmsCode } from "@/api/api";
export default {
  data() {
    return {
      systemInfo: [],
      agree_name: '',
      dialogVisible: false,
      agree: false,
      agree_privacy_agreement: false,
      repassword: '',
      is_send_captcha: false,
      timer: 60,
      loginInfo: {
        name: '',
        username: '',
        password: '',
        user_type: 3,
        phone: '',
        code: ''
      },
      titleName: '服务商',
      clientHeight: '',
      clientWidth: '',
      marginTOP: 300
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let that = this;
    let data = that.$root.useRoute.query;
    if (data) {
      this.loginInfo.user_type = data.userType;
      this.titleName = this.$root.getByName('type', 'title', 'userType', data.userType) || '服务商'; //this.$root.useRoute.name 获取当前的路由名称
    }
    console.log(data);
    that.systemConfigInfo();
    this.clientHeight = `${document.documentElement.clientHeight}`;
    this.clientWidth = `${document.documentElement.clientWidth}`;
    let s = (this.clientHeight - 500) / 2;
    if (s < 0) {
      s = 0;
    }
    this.marginTOP = s;
    window.onresize = function () {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      this.clientWidth = `${document.documentElement.clientWidth}`;
      let s = (this.clientHeight - 500) / 2;
      if (s < 0) {
        s = 0;
      }
      this.marginTOP = s;
      that.$forceUpdate();
    };
  },
  methods: {
    systemConfigInfo() {
      systemConfig().then(res => {
        if (res.data) {
          this.systemInfo = res.data;
        }
      });
    },
    showXY(name) {
      this.dialogVisible = true;
      this.agree_name = name;
    },
    onSubmit() {
      if (!this.loginInfo.username) {
        this.$root.ElMessage.error({
          message: '请输入用户名'
        });
        return false;
      }
      if (!this.loginInfo.name) {
        this.$root.ElMessage.error({
          message: '请输入名称'
        });
        return false;
      }
      if (!this.loginInfo.password) {
        this.$root.ElMessage.error({
          message: '请输入密码'
        });
        return false;
      }
      if (this.loginInfo.password != this.repassword) {
        this.$root.ElMessage.error({
          message: '两次密码不一致'
        });
        return false;
      }
      if (this.loginInfo.phone.length != 11) {
        this.$root.ElMessage.error({
          message: '请输入11位手机号码'
        });
        return false;
      }
      if (!this.loginInfo.code) {
        this.$root.ElMessage.error({
          message: '请输入验证码'
        });
        return false;
      }
      if (!this.agree) {
        this.$root.ElMessage.error({
          message: '请先勾选服务协议和隐私政策'
        });
        return false;
      }
      registerBase(this.loginInfo).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            message: res.msg,
            type: 'success'
          });
          this.goLogin();
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    },
    goLogin() {
      //let routerName=this.$root.getByName('type','user_type','userType',this.loginInfo.user_type) || '商户';//this.$root.useRoute.name 获取当前的路由名称
      let url = '/';
      //if(this.loginInfo.user_type==3){url='/base/checkBase';}
      //if(this.loginInfo.user_type==7){url='/operators/checkOperators';}
      this.$root.useRouter.replace(url);
    },
    sendCode() {
      let that = this;
      if (that.loginInfo.phone.length != 11) {
        this.$root.ElMessage.error({
          message: '请输入11位手机号码'
        });
        return false;
      }
      that.is_send_captcha = true;
      let handler = setInterval(function () {
        that.timer--;
        if (that.timer <= 0) {
          clearInterval(handler);
          that.is_send_captcha = false;
          that.timer = 60;
        }
      }, 1000);
      sendSmsCode({
        phone: that.loginInfo.phone
      }).then(res => {
        console.log(res);
      });
    }
  }
};