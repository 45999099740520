import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-15cc568d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "background": "#F6F6F6",
    "min-height": "100vh"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1);
}