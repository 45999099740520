import "core-js/modules/es.array.push.js";
import menuDiv from "@/view/teacher/menu.vue";
import { getTeacherActivePage } from "@/api/api.js";
export default {
  components: {
    menuDiv
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      List: [],
      stateList: this.$root.stateList,
      pageInfo: {
        pageSize: 5,
        state: '',
        active_name: '',
        reservationTime: '',
        current: 1,
        pages: 1,
        total: 0
      }
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    this.getList();
    this.$refs.menuDiv.getIndex(1); //调用子组件方法
  },
  methods: {
    searchList() {
      this.pageInfo.current = 1;
      this.pageInfo.pages = 1;
      this.pageInfo.total = 0;
      this.List = [];
      this.getList();
    },
    resetList() {
      this.pageInfo.active_name = '';
      this.pageInfo.state = '';
      this.searchList();
    },
    getList() {
      getTeacherActivePage(this.pageInfo).then(res => {
        this.List = this.List.concat(res.data.records);
        this.pageInfo.pages = res.data.pages; //总页数
      });
    },
    loadMore() {
      if (this.pageInfo.page < this.pageInfo.pages) {
        this.pageInfo.page = this.pageInfo.page + 1;
        this.getList();
      }
    },
    changeState(state) {
      this.pageInfo = {
        limit: 5,
        state: state,
        page: 1,
        total: 0
      };
      this.List = [];
      this.getList();
    },
    goTeacherEva(id, activeDate) {
      if (activeDate) {
        activeDate = activeDate + " 00:00:00";
      }
      let activeDateNew = new Date(activeDate).getTime();
      let nowDate = new Date().getTime();
      if (activeDateNew > nowDate) {
        this.$root.ElMessage.error('活动还未开始不能进行评价');
        return false;
      }
      this.$root.useRouter.push({
        path: "/teacher/teacherEva/teacherCourseList",
        query: {
          id: id
        }
      });
    },
    goActiveInfo(id) {
      this.$root.useRouter.push({
        path: "/teacher/active/teacherActiveInfo",
        query: {
          id: id
        }
      });
    }
  }
};