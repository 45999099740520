import HighCharts from 'highcharts';
export default {
  name: "chart",
  // 传入id: 若一个页面出现多个折线图时，id必须不同
  // data: 折线图配置数据
  //props: ['id','data'],
  data() {
    return {
      id: '',
      data: ''
    };
  },
  created() {},
  mounted() {
    //this.showCharts();
  },
  methods: {
    showCharts(id, data) {
      this.id = id;
      this.data = data;
      console.log(id);
      HighCharts.chart(id, data);
    }
  }
};